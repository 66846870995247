import React from 'react';
import PropTypes from 'prop-types';

// TODO: dedupe with moneyvisualizer?
const NumberVisualizer = ({ data }) => (
  <div className='tablevisualizer'>
    <table>
      <tbody>
        <tr>
          <td>
            Mean
          </td>
          <td>
            {data.mean}
          </td>
        </tr>

        <tr>
          <td>
            Median
          </td>
          <td>
            {data.median}
          </td>
        </tr>

        <tr>
          <td>
            Standard deviation
          </td>
          <td>
            {data.standard_deviation}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

NumberVisualizer.propTypes = {
  data: PropTypes.object,
};

export default NumberVisualizer;
