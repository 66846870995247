import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { filter, isEmpty, reject } from 'underscore';
import truncate from 'truncate';
import TagManagerActions from 'actions/tag-manager-actions';
import pluralize from 'modules/pluralize';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../shared/modal';
import { error, success } from '../shared/flash';

const TagManager = (props) => {
  const reservedTagNames = ['renewal'];
  const [tagNames, setTagNames] = useState(props.tagNames);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagTerm, setTagTerm] = useState('');
  const [isWorking, setIsWorking] = useState(false);

  const filteredTags = useMemo(() => {
    if (tagTerm === '') return tagNames;
    return filter(tagNames, (tagName) => {
      return tagName.match(tagTerm);
    });
  }, [tagNames, tagTerm]);

  const onTagToggle = (tag) => {
    if (isTagSelected(tag)) {
      const filtered = reject(selectedTags, (selectedTag) => {
        return selectedTag === tag;
      });
      setSelectedTags([...filtered]);
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const onTagTermChange = (e) => {
    setTagTerm(e.currentTarget.value.toLowerCase().trim());
  };

  const onSubmitClick = () => {
    const data = selectedTags.map((tag) => {
      return props.selectedItems.map((item) => {
        return {
          tag_name: tag,
          taggable_type: 'InitiatedFlow',
          taggable_id: item.id,
        };
      });
    });

    setIsWorking(true);

    if (props.deleteMode) {
      TagManagerActions.removeTaggingInBulk([].concat(...data))
        .done(() => {
          setSelectedTags([]);
          success('Tags removed');
        })
        .fail(() => error())
        .always(() => setIsWorking(false));
    } else {
      TagManagerActions.addTaggingInBulk([].concat(...data))
        .done(() => {
          props.onTagCreate(tagNames);
          setSelectedTags([]);
          success('Tags added');
        })
        .fail(() => error())
        .always(() => setIsWorking(false));
    }
  };

  const onNewTagAdd = (tagName) => {
    setSelectedTags([...selectedTags, tagName]);
    setTagNames([...tagNames, tagName]);
  };

  const isTagSelected = (tag) => {
    return selectedTags.includes(tag);
  };

  const renderAddTagLink = () => {
    if (tagTerm === '' || reservedTagNames.includes(tagTerm)) return;

    if (!props.deleteMode && !tagNames.includes(tagTerm)) {
      return (
        <li
          onClick={() => onNewTagAdd(tagTerm)}
          key={`tag-${tagTerm}`}
        >
          Add new tag {tagTerm}
        </li>
      );
    }
  };

  const renderSelectedIcon = (isSelected) => {
    if (isSelected) {
      if (props.deleteMode) {
        return (
          <FontAwesomeIcon
            icon={icon({ name: 'xmark' })}
            style={{ color: '#B90202', height: '1rem' }}
          />
        );
      }
      return (
        <FontAwesomeIcon
          icon={icon({ name: 'check' })}
          style={{ color: '#74A943', height: '1rem' }}
        />
      );
    }
  };

  const renderTags = () => {
    return (
      <ul className='tagmanager-suggestions scroll'>
        {filteredTags.map((name) => {
          return (
            <li
              onClick={() => onTagToggle(name)}
              key={`tag-${name}`}
            >
              {truncate(name, 50)}
              {renderSelectedIcon(isTagSelected(name))}
            </li>
          );
        })}
        {renderAddTagLink()}
      </ul>
    );
  };

  const renderHeaderTitle = () => {
    const submissionCount = props.selectedItems.length;
    const actionText = props.deleteMode ? 'Untag' : 'Tag';
    return (
      <div className='tagmanager-header-container'>
        <h1>
          {`${actionText} ${submissionCount > 1 ? 'these' : 'this'} ${submissionCount} ${pluralize('submission', submissionCount)}`}
        </h1>
        <FontAwesomeIcon
          className='clickable'
          icon={icon({ name: 'x' })}
          style={{ color: '#0463B7', height: '1rem' }}
          onClick={() => { if (!isWorking) props.onClose(); }}
        />
      </div>
    );
  };

  return (
    <Modal enableCloseIfOutside={false}>
      {renderHeaderTitle()}
      <div className='searchbar'>
        <label key='search'>
          Search Tags
        </label>
        <input type='text' onChange={onTagTermChange} />
      </div>
      {renderTags()}
      <div className='align-right'>
        <button
          type='button'
          className={`btn-secondary margin-right ${isWorking ? 'disabled' : ''}`}
          disabled={isWorking}
          onClick={props.onClose}
        >
          Cancel
        </button>
        <button
          type='button'
          className={`btn-primary ${(isWorking || isEmpty(selectedTags)) ? 'disabled' : ''}`}
          disabled={isWorking || isEmpty(selectedTags)}
          onClick={onSubmitClick}
        >
          {isWorking ? 'Working...' : `${props.deleteMode ? 'Untag' : 'Tag'} ${pluralize('submission', props.selectedItems.length)}`}
        </button>
      </div>
    </Modal>
  );
};

TagManager.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.shape({})),
  onClose: PropTypes.func.isRequired,
  tagNames: PropTypes.arrayOf(PropTypes.shape({})),
  deleteMode: PropTypes.bool,
  onTagCreate: PropTypes.func.isRequired,
};

export default TagManager;
