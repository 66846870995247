import React, { useRef } from 'react';
import dayjs from 'dayjs';
import ajaxDownload from 'modules/ajax-download';


const File = ({ file }) => {
  const detailsNode = useRef(null);
  const expandIconNode = useRef(null);

  const toggleDetails = () => {
    detailsNode.current.classList.toggle('expand');
    expandIconNode.current.classList.toggle('open');
  };

  const onClickDownload = () => {
    ajaxDownload(file.url.original, file.file_file_name);
  }

  return (
    <div className='ifslidingpanel-file'>
      <div className='file-header' onClick={toggleDetails}>
        <b>
          {file.file_file_name}
        </b>
        <i ref={expandIconNode} className={`icon-arrow-left bigger`}></i>
      </div>
      <div ref={detailsNode} className='file-details'>
        <table className='margin-top'>
          <tbody>
            <tr>
              <td>
                Step
              </td>
              <td>
                {file.step}
              </td>
            </tr>
            <tr>
              <td>
                Field
              </td>
              <td>
                {file.field}
              </td>
            </tr>
            <tr>
              <td>
                Upload date
              </td>
              <td>
                {dayjs(file.updated_at).format('LL')}
              </td>
            </tr>
            <tr>
              <td>
                Uploaded by
              </td>
              <td>
                {file.admin_uploaded ? 'Admin' : 'Applicant'}
              </td>
            </tr>
          </tbody>
        </table>
        <div className='file-actions'>
          <span style={{ cursor: 'pointer' }} onClick={onClickDownload}>Download</span>
        </div>
      </div>
    </div>
  );
};

export default File;
