import React, { useRef } from 'react';
import dayjs from 'dayjs';


const RepoFile = ({ file }) => {
  const detailsNode = useRef(null);
  const expandIconNode = useRef(null);

  const toggleDetails = () => {
    detailsNode.current.classList.toggle('expand');
    expandIconNode.current.classList.toggle('open');
  };

  return (
    <div className='ifslidingpanel-file'>
      <div className='file-header' onClick={toggleDetails}>
        <b>
          {file.filename}
        </b>
        <i ref={expandIconNode} className={`icon-arrow-left bigger`}></i>
      </div>
      <div ref={detailsNode} className='file-details'>
        <table className='margin-top'>
          <tbody>
            <tr>
              <td>
                Uploaded by
              </td>
              <td>
                {file.uploaded_by}
              </td>
            </tr>
            <tr>
              <td>
                Upload date
              </td>
              <td>
                {dayjs(file.updated_at).format('LL')}
              </td>
            </tr>
          </tbody>
        </table>
        <div className='file-actions'>
          <a href={file.url}>Download</a>
        </div>
      </div>
    </div>
  );
};

export default RepoFile;
