import React from 'react';
import PropTypes from 'prop-types';

import Select from 'components/shared/select';
import useOnclickOutside from 'react-cool-onclickoutside';

const SEARCH_TYPES = [
  { value: 'exact', label: 'Exact Match' },
  { value: 'contains', label: 'Contains' },
];

const SearchBar = ({
  onOutsideClick, isActive, children, onFocus, searchTypeChanged, searchType, search, searchButtonId,
}) => {
  const wrapperRef = useOnclickOutside(onOutsideClick);

  const focusedClass = isActive ? 'active' : null;

  return (
    <div ref={wrapperRef} className={`searchbox cf ${focusedClass}`}>
      {children}
      {isActive && (
        <Select
          options={SEARCH_TYPES}
          onFocus={onFocus}
          onChange={searchTypeChanged}
          value={SEARCH_TYPES.find(({ value }) => value === searchType)}
          isSearchable={false}
          menuPosition='fixed'
          styles={{
            container: (provided) => ({
              ...provided,
              '@media (max-width: 480px)': {
                position: 'absolute',
                right: '0px',
                top: '5px',
              },
              'min-width': '110px',
            }),
            control: (provided) => ({
              ...provided,
              lineHeight: '3rem',
              height: '3rem',
              // This is to override the __control styles
              border: 'none',
              backgroundColor: 'inherit !important',
            }),
            // The styling for the value
            singleValue: (provided) => ({
              ...provided,
              color: '#597A91',
              // This is one modular scale bigger but we don't have
              // a direct reference to that here
              fontSize: '1.2rem',
            }),
          }}
        />
      )}
      <button
        type='button'
        className='btn-primary'
        onClick={search}
        id={searchButtonId}
      >
        Search
      </button>
    </div>
  );
};

SearchBar.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  onOutsideClick: PropTypes.func,
  onFocus: PropTypes.func,
  search: PropTypes.func,
  searchType: PropTypes.string,
  searchTypeChanged: PropTypes.func,
  searchButtonId: PropTypes.string,
};

SearchBar.defaultProps = {
  // Setting this as a defaultProp ensures that the selector shows
  // up on the search bar on the public search page
  isActive: true,
  onOutsideClick: () => {},
  searchButtonId: '',
};

export default SearchBar;
