import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import DashboardActions from 'actions/dashboard-actions';
import { debounce } from 'underscore';
import LoadingSpinner from 'components/shared/loading-spinner';
import DashboardCell from './dashboard-cell';
import SortingHeaderCell from './sorting-header-cell'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

const { Table } = require('fixed-data-table-2');
const { Column } = require('fixed-data-table-2');
const { Cell } = require('fixed-data-table-2');

const MainDashboard = ({
  resizeCount,
  initiatedFlows,
  selectedItems,
  onFlowBoxClick,
  rowsCount,
  hasTemplates,
  noTeams,
  activeOrder,
  sortingFn,
}) => {
  const dashboardElement = useRef();

  const [tableWidth, setTableWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  useEffect(() => {
    onResize();
  }, [resizeCount, onResize]);

  const onResize = debounce(() => {
    setTableHeight(contentHeight());
    setTableWidth(contentWidth);
  }, 100);

  function dashboardHasContent() {
    return (initiatedFlows !== null
            && initiatedFlows.length > 0);
  }

  function isLoading() {
    return initiatedFlows === null;
  }

  function buildCell(cellName, columnAttrs) {
    const initiatedFlow = initiatedFlows[columnAttrs.rowIndex];

    if (!initiatedFlow) {
      DashboardActions.nullCellRequested(columnAttrs.rowIndex);
      return (
        <Cell key='dashboardloadingcell'>
          <div className='dashboard-loadingcell' />
        </Cell>
      );
    }

    return (
      <DashboardCell
        item={initiatedFlow}
        cellName={cellName}
        selectedItems={selectedItems}
        onFlowBoxClick={onFlowBoxClick}
      />
    );
  }

  function contentWidth() {
    return dashboardElement.current.offsetWidth;
  }

  function contentHeight() {
    return dashboardElement.current.offsetHeight;
  }

  function rowResponsibilityClass(rowIndex) {
    if (!initiatedFlows[rowIndex]) { return; }

    const initiatedFlow = initiatedFlows[rowIndex];
    if (initiatedFlow.current_user_responsible) {
      return 'responsible';
    }
  }

  function renderGetStartedLink() {
    if (noTeams) {
      return <a className='btn-primary' href='/teams/new' data-method='get'>Create your first team</a>;
    }

    return <a className='btn-primary' href='/flow_templates' data-method='post'>Create your first form</a>;
  }

  function renderContent() {
    if (isLoading()) {
      return (
        <div className='dashboard-loading'>
          <LoadingSpinner size='large' />
        </div>
      );
    }

    if (dashboardHasContent()) {
      return (
        <Table
          rowsCount={rowsCount}
          rowHeight={50}
          headerHeight={35}
          width={tableWidth}
          height={tableHeight}
          rowClassNameGetter={rowResponsibilityClass}
        >

          <Column
            header={<Cell />}
            cell={(columnAttrs) => {
              return buildCell('checkbox', columnAttrs);
            }}
            width={35}
            cellClassName='dashboard-processcheckbox'
          />

          <Column
            header={<Cell />}
            cell={(columnAttrs) => {
              return buildCell('renewal', columnAttrs);
            }}
            width={35}
            cellClassName='dashboard-renewal'
          />

          <Column
            header={<SortingHeaderCell title='ID' columnId='process_id' activeOrder={activeOrder} onSorting={sortingFn} />}
            cell={(columnAttrs) => {
              return buildCell('id', columnAttrs);
            }}
            width={70}
            cellClassName='dashboard-processid'
          />

          <Column
            header={<Cell>Identifier</Cell>}
            cell={(columnAttrs) => {
              return buildCell('identifier', columnAttrs);
            }}
            width={150}
          />

          <Column
            header={<Cell>Form</Cell>}
            cell={(columnAttrs) => {
              return buildCell('template_name', columnAttrs);
            }}
            width={225}
          />

          <Column
            header={<Cell>Applicant</Cell>}
            cell={(columnAttrs) => {
              return buildCell('applicant', columnAttrs);
            }}
            width={200}
            flexGrow={1}
          />

          <Column
            header={<Cell>Action</Cell>}
            cell={(columnAttrs) => {
              return buildCell('action', columnAttrs);
            }}
            width={150}
          />

          <Column
            header={<SortingHeaderCell title='Launched' columnId='date_launched' activeOrder={activeOrder} onSorting={sortingFn} />}
            cell={(columnAttrs) => {
              return buildCell('launched_at', columnAttrs);
            }}
            width={120}
          />

          <Column
            header={<Cell>Status</Cell>}
            cell={(columnAttrs) => {
              return buildCell('status', columnAttrs);
            }}
            width={100}
          />

          <Column
            header={<SortingHeaderCell title='Last activity' columnId='last_activity' activeOrder={activeOrder} onSorting={sortingFn} />}
            cell={(columnAttrs) => {
              return buildCell('last_activity', columnAttrs);
            }}
            width={140}
          />

          <Column
            header={<SortingHeaderCell title='Current step' columnId='current_step_since' activeOrder={activeOrder} onSorting={sortingFn} />}
            cell={(columnAttrs) => {
              return buildCell('current_step', columnAttrs);
            }}
            width={225}
          />

          <Column
            header={<Cell>Assignees</Cell>}
            cell={(columnAttrs) => {
              return buildCell('responsible', columnAttrs);
            }}
            width={195}
            flexGrow={1}
          />

        </Table>
      );
    }

    if (!hasTemplates) {
      return (
        <div className='dashboard-message relative'>
          <h1>Welcome to ClearForms</h1>
          <p>There’s nothing here yet. Ready to get started?</p>
          {renderGetStartedLink()}<br />
          <p className='margin-top'>or check out our <a target='_blank' href='https://help.citygrows.com/en/collections/1543432-i-work-for-a-government-and-want-to-set-up-citygrows-for-permitting-licensing-or-internal-workflows' rel='noreferrer'>getting started guide</a>.</p>
        </div>
      );
    }
  }

  return (
    <div ref={dashboardElement} className='dashboard-maincontent-rowwrap overflow-x-auto'>
      {renderContent()}
    </div>
  );
};

MainDashboard.propTypes = {
  resizeCount: PropTypes.number.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.number),
  initiatedFlows: PropTypes.arrayOf(PropTypes.shape({
    current_user_responsible: PropTypes.bool,
  })),
  hasTemplates: PropTypes.bool.isRequired,
  totalInitiatedFlowCount: PropTypes.number,
  rowsCount: PropTypes.number,
};

MainDashboard.defaultProps = {
  selectedItems: [],
};

export default MainDashboard;
