import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import AdvancedFilterAPI from 'apis/advanced-filter-api';
import flash from 'components/shared/flash';
import Modal from 'components/shared/modal';
import SlidingToggle from 'components/shared/sliding-toggle';


const SaveFilterModal = ({
  filters,
  onClose,
  kind,
  loadedFilter,
  dispatch,
}) => {
  const [filterName, setFilterName] = useState('');
  const [error, setError] = useState(false);
  const [saveAsOn, setSaveAsOn] = useState(false);
  const [saveAsDefaultOn, setSaveAsDefaultOn] = useState(false);

  useEffect(() => {
    if (saveAsOn && loadedFilter.default) {
      setSaveAsDefaultOn(true);
    }
  }, [saveAsOn]);

  const onSaveFilter = () => {
    if (saveAsOn) {
      AdvancedFilterAPI.update(loadedFilter.id, kind, filters, saveAsDefaultOn)
        .done((res) => {
          flash.success('Filter saved.');
          dispatch({
            type: 'updateSavedFilter',
            value: res.filter,
          });
      })
        .fail(() => flash.error('Could not save the filter.'))
        .always(() => onClose());
    } else {
      if (filterName.length) {
        AdvancedFilterAPI.create(filterName, kind, filters, saveAsDefaultOn)
          .done((res) => { 
            flash.success('Filter saved.');
            dispatch({
              type: 'addSavedFilter',
              value: res.filter,
            });
          })
          .fail(() => flash.error('Could not save the filter.'))
          .always(() => onClose());
      } else {
        setError(true);
      };
    }
  };

  const onFilterNameChange = (e) => {
    if (error) { setError(false); }

    setFilterName(e.target.value);
  };

  const renderSaveAsDefault = () => {
    return (
      <div style={{ marginTop: '1rem' }}>
        <SlidingToggle
          label={`Save as Default`}
          on={saveAsDefaultOn}
          onToggle={() => setSaveAsDefaultOn(!saveAsDefaultOn)}
        />
      </div>
    );
  };

  const renderSaveAsCurrent = () => {
    if (!loadedFilter) { return null; }

    return (
      <div style={{ marginTop: '1rem' }}>
        <SlidingToggle
          id='save-as-current-filter'
          label={`Save as "${loadedFilter.name}"`}
          on={saveAsOn}
          onToggle={() => setSaveAsOn(!saveAsOn)}
        />
      </div>
    );
  };

  return (
    <Modal
      className='manage-filter-modal'
      onOutsideClick={onClose}
    >
      <div className='manage-filter-modal-header'>
        <h1>Save Filter</h1>
        <FontAwesomeIcon icon={solid('xmark')} size='lg' className='close-modal-xmark' onClick={onClose} />
      </div>
      <div>
        <p>
          Saved filters empower you to easily access the information you need.
          By storing your custom filter settings, you can retrieve them later with just a click.
          No more recreating filters from scratch.
        </p>
      </div>
      <div className='manage-filter-modal-form'>
        <span>Filter Name</span>
        <input
          type='text'
          id='save-filter-name-input'
          onChange={onFilterNameChange}
          className={error ? 'error' : undefined}
          placeholder='Enter filter name'
          style={{ width: '300px' }}
          disabled={saveAsOn}
        />
        {renderSaveAsCurrent()}
        {renderSaveAsDefault()}
      </div>
      <div className='manage-filter-modal-footer'>
        <button onClick={onClose} className='btn-secondary'>Cancel</button>
        <button id='save-filter-button' onClick={onSaveFilter} className='btn-primary'>Save Filter</button>
      </div>
    </Modal>
  );
};

export default SaveFilterModal;
