import React from 'react';
import AddressValidator from './address_input/address-validator';

class AddressInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      value: this.props.defaultValue,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.props.completionBegun();
    }
  }

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleSave = (address) => {
    this.setState({ focused: false, value: address });
    this.props.onChange({}, address);
  };

  handleCancel = () => {
    this.props.completionBegun();
    this.setState({ focused: false });
  };

  render() {
    if (this.state.focused) {
      return (
        <AddressValidator defaultValue={this.state.value} handleSave={this.handleSave} handleCancel={this.handleCancel} />
      );
    }

    return (
      <input name='address' ref={this.props.innerRef} aria-required={this.props.required} onFocus={this.handleFocus} disabled={this.props.disabled} type='text' defaultValue={this.state.value} autoComplete='new-password' />
    );
  }
}

export default AddressInput;
