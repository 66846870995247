import PropTypes from 'prop-types';
import React from 'react';
import CollectedDataAPI from 'apis/collected-data-api';
import FormData from 'form-data';
import _ from 'underscore';
import Flash from 'components/shared/flash';
import LoadingSpinner from 'components/shared/loading-spinner';
import FileDropzone from 'components/shared/file-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

class FileInput extends React.Component {
  state = { uploading: false };

  onFileAccept = (file) => {
    this.setState({ uploading: true });

    this.uploadDirectToS3(file)
      .done((url) => this.props.onChange(url, file))

      .fail(this.onUploadError)

      .always(() => this.setState({ uploading: false }));
  };

  onUploadError = () => {
    Flash.render({
      message: 'There was an error uploading your file. Please try again.',
      type: 'error',
    });
  };

  uploadDirectToS3 = (file) => {
    const d = $.Deferred();

    this.fetchPreauth(file.name)
      .done((res) => {
        this.postToS3(res.url, res.form_data, file)

          .done((s3Res) => {
            const url = this.findFileURL(s3Res);
            d.resolve(url);
          })

          .fail(d.reject);
      })

      .fail(d.reject);

    return d;
  };

  findFileURL = (res) => {
    const $xml = $(res);
    return $xml.find('Location').text();
  };

  fetchPreauth = () => {
    return CollectedDataAPI.s3Preauth();
  };

  postToS3 = (url, authFields, file) => {
    const formData = new FormData();
    formData.append('key', authFields.key);
    delete authFields.key;
    _.each(authFields, (k, v) => formData.append(v, k));
    formData.append('file', file);

    const request = $.ajax(url, {
      contentType: false,
      data: formData,
      method: 'POST',
      dataType: 'XML',
      processData: false,
    });

    return request;
  };

  loadingHTML = () => {
    return (
      <div className='dropzone-content-container'>
        <LoadingSpinner size='medium' />;
      </div>
    );
  };

  disabledHTML = () => {
    return (
      <div className='dropzone-content-container'>
        <span className='icon icon-lock biggest' />
      </div>
    );
  };

  blockedContent = () => {
    if (this.props.disabled) { return this.disabledHTML(); }
    if (this.state.uploading) { return this.loadingHTML(); }
  };

  mainContent = () => {
    return (
      <div className='dropzone-content-container'>
        <div><FontAwesomeIcon icon={solid('cloud-arrow-up')} size='2xl' style={{ color: '#7d99ac' }} /></div>
        <div>Drag and drop file here</div>
        <div className='padding-top'>
          <hr />
          <div class="inline-block">or</div>
          <hr />
        </div>
        <div className='padding-top'>Click to browse files</div>
      </div>
    );
  };

  isBlocked = () => {
    return this.state.uploading || this.props.disabled;
  };

  render() {
    return (
      <FileDropzone onFileAccept={this.onFileAccept} disabled={this.props.disabled} >
        {this.isBlocked() ? this.blockedContent() : this.mainContent()}
      </FileDropzone>
    );
  }
}

FileInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default FileInput;
