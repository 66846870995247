import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import InitiatedFlowAPI from 'apis/initiated-flow-api';
import File from './documents_and_files/file'
import RepoFile from './documents_and_files/repo-file';
import DownloadLinks from './documents_and_files/download-links'
import Output from 'components/initiated_flow/process/output/output';
import LoadingSpinner from 'components/shared/loading-spinner';
import FileInput from 'components/shared/file-input';


const DocumentsAndFiles = ({
  initiatedFlowId,
  issuedOutputs,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [repoFiles, setRepoFiles] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    InitiatedFlowAPI.
      documentsAndFiles(initiatedFlowId).
      done((res) => setFiles(res.files)).
      done((res) => setRepoFiles(res.repo_files)).
      always(() => setIsLoading(false));
  }, []);

  const onFileSelect = (e) => {
    const file = e.currentTarget.files[0];
    setIsUploading(true);

    InitiatedFlowAPI.
      uploadRepoFile(initiatedFlowId, file).
      done((res) => setRepoFiles([...repoFiles, res.repo_file])).
      always(() => setIsUploading(false));
  };

  const renderOutputs = () => {
    const outputsNodes = issuedOutputs.map((o) => {
      return (
        <div key={o.id} className='margins'>
          <Output
            output={o}
            style='mini'
            collapse
          />
        </div>
      );
    });

    return (
      <div>
        <h3>Outputs</h3>
        {outputsNodes}
      </div>
    );
  };

  const renderFiles = () => {
    const filesNodes = files.map((f) => {
      return (
        <div key={f.id} className='margins'>
          <File file={f} />
        </div>
      );
    });

    const repoFilesNodes = repoFiles.map((f) => {
      return (
        <div key={f.id} className='margins'>
          <RepoFile file={f} />
        </div>
      );
    });

    const anyFile = !!(filesNodes.length || repoFilesNodes.length);

    const uploadAction = () => {
      return (
        <div className='ifslidingpanel-upload-repo-file'>
          {isUploading
            ? <>
                <LoadingSpinner size='tiny' className='vmiddle' />
                <span className='color-text-medium'>Uploading...</span>
              </>
            : <FileInput onChange={onFileSelect} label='+ Upload new file' />
          }
        </div>
      );
    }

    return (
      <div>
        <div style={{ display: 'flex' }}>
          <h3>Files</h3>
          {uploadAction()}
        </div>
        {filesNodes}
        {repoFilesNodes}
        {anyFile && (
          <DownloadLinks outputId={issuedOutputs[0].id} />
        )}
      </div>
    );
  };

  return (
    <>
      {renderOutputs()}
      {isLoading
        ? <LoadingSpinner size='medium' className='vmiddle' />
        : renderFiles()}
    </>
  );
};

DocumentsAndFiles.propTypes = {
  initiatedFlowId: PropTypes.number.isRequired,
  issuedOutputs: PropTypes.array,
  onCloseClick: PropTypes.func,
  transitionRef: PropTypes.object,
};

export default DocumentsAndFiles;
