import React from 'react';
import PropTypes from 'prop-types';

const FlashItem = ({ position = 0, type, message }) => {
  const pixelOffset = position * 6;

  return (
    <div
      style={{ bottom: `${pixelOffset}em` }}
      className={`flash-${type}`}
      dangerouslySetInnerHTML={{ __html: message }}
    />
  );
};

FlashItem.propTypes = {
  position: PropTypes.number,
  type: PropTypes.string,
  message: PropTypes.string,
};

export default FlashItem;
