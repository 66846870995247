import React from 'react';

/**
 * OG content
  <li><NavLink  to='/'
                activeClassName={'active'}
                exact
                id='processestab'
                tabIndex='1'>Form submissions</NavLink></li>
  <li><a href='#'>Form reviews</a></li>
  <li><NavLink  to='/outputs'
                activeClassName={'active'}
                isActive={(_, location) => { return location.pathname.match(/^\/outputs/); }}
                id='outputstab'
                tabIndex='1'>Form outputs</NavLink></li>
 */

const PrimaryTabs = () => {
  const activeTab = () => {
    const path = window.location.pathname;

    if (path.match(/^\/(workflows|master_templates).*/)) { return 'workflows' }
    if ((path.match(/^\/processes.*/)) ||
        (path.match(/^\/initiated_flows\/?.*/) && !path.match(/.+review.+/))) {
          return 'processes'
        }
    if (path === '/' || path.match(/^\/metrics/)) { return 'metrics'}
    if (path.match(/^\/outputs.*/)) { return 'outputs'}
    if (path.match(/^\/exports|^\/batch_export_templates/)) { return 'exports'}
    if (path.match(/^\/teams\/?([a-zA-Z0-9-_]+)\/(people|entities)$/)) { return 'directory' }
    if (path.match(/^\/teams\/?([a-zA-Z0-9-_]+)?(\/(custom_entities|master_templates|roles|merchant_accounts|transactions|transactions\/cash_and_checks|charge_disputes)\/?)?()$/)) {
      return 'teams'
    }
    if (path.match(/\/review\//)) { return 'review' }
    if (path.match(/^\/my_tasks.*/)) { return 'tasks'}

    return ''
  }

  const tab = activeTab();

  return (
    <>
      <div id='cflogo'></div>
      <div className='tabheader'>
        <ul>
          <li>
            <a href='/metrics'
               className={`${tab === 'metrics' ? 'active' : ''}`}
            >
              Dashboard
            </a>
          </li>
          <li>
            <a
              href='/workflows'
              className={`${tab === 'workflows' ? 'active' : ''}`}
            >
              Forms Manager
            </a>
          </li>
          <li>
            <a href='/processes'
              id='processestab'
              tabIndex='1'
              className={`${tab === 'processes' ? 'active' : ''}`}
            >
              Form Submissions
            </a>
          </li>
          <li>
            <a href='/my_tasks'
              id='taskstab'
              tabIndex='1'
              className={`${tab === 'tasks' ? 'active' : ''}`}
            >
              My Tasks
            </a>
          </li>
          <li>
            <a
              href='/reviews'
              className={`${tab === 'review' ? 'active' : ''}`}
            >
              Form Reviews
            </a>
          </li>
          <li>
            <a
              href='/outputs'
              id='outputstab'
              tabIndex='1'
              className={`${tab === 'outputs' ? 'active' : ''}`}
            >
              Form Outputs
            </a>
          </li>
          <li>
            <a
              href='/teams'
              id='teamstab'
              tabIndex='1'
              className={`${tab === 'teams' ? 'active' : ''}`}
            >
              Teams
            </a>
          </li>
          <li>
            <a
              href='/teams/directory'
              className={`${tab === 'directory' ? 'active' : ''}`}
            >
              Directory
            </a>
          </li>
          <li>
            <a
              href='/exports'
              className={`${tab === 'exports' ? 'active' : ''}`}
            >
              Exports Manager
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default PrimaryTabs;
