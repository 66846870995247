import React from 'react';
import _ from 'underscore';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Alertconfirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
    };

    this.challengeResponse = React.createRef();
  }

  renderButtons = () => {
    const confirmButton = <div id='alertconfirm-confirmbutton' className='btn-primary' onClick={this.onConfirmClick.bind(this)}>Okay</div>;

    if (this.props.type === 'confirm') {
      return (
        <div>
          <div className='btn-secondary' onClick={this.props.onCancel}>Never mind</div>
          {confirmButton}
        </div>
      );
    }
    return (
      <div>
        {confirmButton}
      </div>
    );
  };

  onConfirmClick = () => {
    this.setState({ errorMessage: null });

    if (!this.props.challenge) {
      this.props.onConfirm();
    } else if (this.props.challengeAnswer.toString() === this.challengeResponse.current.value) {
      this.props.onConfirm();
    } else {
      this.setState({
        errorMessage: 'Invalid response',
      });
    }
  };

  renderErrorMessage = () => {
    if (this.state.errorMessage) {
      return <div className='margin-top text-alert strong align-right'>{this.state.errorMessage}</div>;
    }
  };

  renderMessageBody = () => {
    const challengeHTML = this.props.challenge ? (
      <div className='margin-bottom'>
        <div className='linebreak' />
        <span>{this.props.challenge}</span>
        <input type='text' ref={this.challengeResponse} className='box' size='6' />
        {this.renderErrorMessage()}
      </div>
    ) : null;

    const bodyTag = _.isString(this.props.body)
      ? <div dangerouslySetInnerHTML={{ __html: this.props.body }} />
      : this.props.body;

    return (
      <div>
        {bodyTag}
        {challengeHTML}
      </div>
    );
  };

  render() {
    return (
      <div id='alertconfirm' className={`alertconfirm-container ${this.props.klass ?? ''}`}>
        <div className='cf'>
          <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} size='2xl' className='alertconfirm-icon' />
          <dl className='alertconfirm-text margin-bottom'>
            <dt className='alertconfirm-title'>{this.props.title}</dt>
            <dd className='alertconfirm-message'>
              {this.renderMessageBody()}
            </dd>
          </dl>
        </div>
        <div className='alertconfirm-actions align-right'>
          {this.renderButtons()}
        </div>
      </div>
    );
  }
}

export default Alertconfirm;
