import React from 'react';
import PropTypes from 'prop-types';
import Accounting from 'accounting';
import TimeAgo from 'react-timeago';

class Receipt extends React.Component {
  refundReason() {
    if (!this.props.admin || !this.props.charge.refund_reason) { return; }

    return (
      <tr>
        <td>Refund Reason:</td>
        <td>{this.props.charge.refund_reason || '---'}</td>
      </tr>
    );
  }

  checkNumber = () => {
    const number = this.props.activeStep.check_number;
    if (!number || number.length === 0) {
      return;
    }

    return (
      <tr>
        <td>Check Number:</td>
        <td>{number}</td>
      </tr>
    );
  };

  nonChargeReceipt = () => {
    const paymentMethod = (this.props.activeStep.check_paid_amount ? 'check' : 'cash');
    let amountPaid;

    switch (paymentMethod) {
    case 'check':
      amountPaid = this.props.activeStep.check_paid_amount;
      break;
    case 'cash':
      amountPaid = this.props.activeStep.cash_paid_amount;
      break;
    }

    const formattedAmount = Accounting.formatMoney(amountPaid / 100);

    return (
      <span>
        <table className='receipttable'>
          <tbody>
            <tr>
              <td>Paid:</td>
              <td className='color-green bold'>{formattedAmount}</td>
            </tr>

            <tr>
              <td>Method:</td>
              <td>{paymentMethod}</td>
            </tr>
            {paymentMethod === 'check' && this.checkNumber()}
          </tbody>
        </table>
      </span>
    );
  };

  renderPendingText = () => {
    if (this.props.charge.method === 'ACH Payment') {
      return 'Processing - may take 4 business days to complete';
    }

    return 'Processing';
  };

  renderStatus = () => {
    switch (this.props.charge.status) {
    case 'refunded':
      return <span>Refunded <TimeAgo className='timeago' date={this.props.charge.refunded_at} /></span>;
    case 'disputed':
      return <span className='text-semialert'>Disputed</span>;
    case 'pending':
      return <span>{this.renderPendingText()}</span>;
    case 'refund_pending':
      return <span>Processing Refund</span>;
    case 'paid':
      return <span className=''>Processed</span>;
    case 'failed':
      return <span className='text-alert bold'>Failed</span>;
    }
  };

  refundDidRequireAuthorization = () => {
    return this.props.charge.refunded_at && this.props.charge.refund_authorizer;
  };

  renderAuthorizationDetails = () => {
    // Only admins should see this

    if (!this.props.admin || !this.refundDidRequireAuthorization()) { return; }

    return (
      <>
        <tr>
          <td>Refund requested by:</td>
          <td>{this.props.charge.refund_requester.name}</td>
        </tr>

        <tr>
          <td>Refund request time:</td>
          <td><TimeAgo date={this.props.charge.refund_requested_at} className='timeago' /></td>
        </tr>

        <tr>
          <td>Refund authorized by:</td>
          <td>{this.props.charge.refund_authorizer.name}</td>
        </tr>
      </>
    );
  };

  renderProcessed = () => {
    return (
      <tr>
        <td>Processed:</td>
        <td><TimeAgo date={this.props.charge.created_at} className='timeago' /></td>
      </tr>
    );
  };

  renderFailureDetails = () => {
    if (this.props.charge.status === 'refund_failed' || this.props.charge.status === 'failed') {
      return (
        <tr>
          <td>Details:</td>
          <td><span>{this.props.charge.failure_message}</span></td>
        </tr>
      );
    }
  };

  chargeReceipt = () => {
    const showDownloadLink = this.props.charge.status === 'paid' || this.props.charge.status === 'refunded';

    return (
      <div>
        <table className='receipttable'>
          <tbody>
            <tr>
              <td>Amount:</td>
              <td className='color-green bold'>{Accounting.formatMoney(this.props.charge.amount / 100)}</td>
            </tr>

            <tr>
              <td>Method:</td>
              <td>{this.props.charge.method}</td>
            </tr>

            <tr>
              <td>By:</td>
              <td>{this.props.charge.user.name}</td>
            </tr>

            {this.props.charge.status === 'paid' && this.renderProcessed()}

            <tr>
              <td>Status:</td>
              <td>{this.renderStatus()}</td>
            </tr>

            {this.renderFailureDetails()}
            {this.renderAuthorizationDetails()}
            {this.refundReason()}
          </tbody>
        </table>

        {showDownloadLink && <a className='btn-secondary block margin-top' target='_blank' href={`/charges/${this.props.charge.confirmation_number}.pdf`} rel='noreferrer'>Download receipt</a>}
      </div>
    );
  };

  render() {
    if (this.props.charge) {
      return this.chargeReceipt();
    }
    return this.nonChargeReceipt();
  }
}

Receipt.propTypes = {
  admin: PropTypes.bool.isRequired,
};

export default Receipt;
