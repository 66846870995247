import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { isNull } from 'underscore';
import Modal from './modal';

const Picker = (props) => {
  const [selected, setSelected] = useState(null);
  const [saving, setSaving] = useState(false);

  const onSelect = (id) => {
    setSelected(id);
    props.onSelect(id);
  };

  const isSelected = (id) => {
    return id === selected;
  };

  const getClassName = (id) => {
    return `icon-container ${isSelected(id) ? 'selected' : ''}`;
  };

  const getColumnClassName = () => {
    if (!props.columns || (props.columns < 2 || props.columns > 6)) {
      return 'picker-2';
    }
    return `picker-${props.columns}`;
  };

  const renderText = (item) => {
    if (item.description) {
      return (
        <dd>
          <strong>{item.title}</strong>
          <p>{item.description}</p>
        </dd>
      );
    }
    return (
      <dd>
        <span>{item.title}</span>
      </dd>
    );
  };

  const onSave = () => {
    setSaving(true);
    props.onSave().always(() => setSaving(false));
  };

  return (
    <Modal className={`picker ${getColumnClassName()} nopadding`} onOutsideClick={props.onClose}>
      <div className='picker-container'>
        <div className='picker-container-header'>
          <div className='title'>
            <h1>{props.title}</h1>
            <FontAwesomeIcon
              className='clickable'
              icon={icon({ name: 'xmark' })}
              style={{ color: '#0463B7', height: '1.5rem' }}
              onClick={() => props.onClose()}
            />
          </div>
        </div>
        <div className='picker-container-body'>
          <ul>
            {props.items.map((item) => {
              return (
                <li
                  onClick={() => onSelect(item.id)}
                >
                  <dl>
                    <dt>
                      <div className={getClassName(item.id)}>
                        {item.icon}
                        {isSelected(item.id) && (
                          <div className='selectionbox'>
                            <label className='checkbox'>
                              <input value='true' type='checkbox' name='selected' checked={isSelected(item.id)} />
                              <span />
                            </label>
                          </div>
                        )}
                      </div>
                    </dt>
                    {renderText(item)}
                  </dl>
                </li>
              );
            })}
          </ul>
        </div>
        <div className='picker-container-footer'>
          <button type='button' onClick={props.onClose} className='btn-secondary'>Cancel</button>
          <button
            className={`btn-primary ${isNull(selected) ? 'disabled' : ''}`}
            disabled={(isNull(selected) || saving)}
            onClick={onSave}
          >
            {saving ? 'Working...' : props.saveTitle}
          </button>
        </div>
      </div>
    </Modal>
  );
};

Picker.propTypes = {
  title: PropTypes.string.isRequired,
  saveTitle: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    icon: PropTypes.shape({}).isRequired,
  })),
  columns: PropTypes.number,
};

export default Picker;
