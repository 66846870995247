import Shield from 'modules/shield';
import PropTypes from 'prop-types';
import React from 'react';
import CloseIfOutsideWrapper from 'components/shared/close-if-outside-wrapper';

/*
This component is used to wrap other components ie:

render: function(){
  return(
    <Modal>
      <YourComponent />
    </Modal>
  )
*/

class Modal extends React.Component {
  componentDidMount() {
    Shield.on();
  }

  componentWillUnmount() {
    Shield.off();
  }

  onClose = () => {
    if (this.props.onOutsideClick) {
      this.props.onOutsideClick();
    }
  };

  className = () => {
    const classes = ['centered'];

    if (this.props.className) {
      classes.push(this.props.className);
    }

    if (!this.props.scroll) {
      classes.push('modal-noscroll');
    }

    return classes.join(' ');
  };

  render() {
    if (this.props.enableCloseIfOutside) {
      return (
        <CloseIfOutsideWrapper onClose={this.onClose}>
          <div id='modal' className={this.className()}>
            {this.props.children}
          </div>
        </CloseIfOutsideWrapper>
      );
    }
    return (
      <div id='modal' className={this.className()}>
        {this.props.children}
      </div>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onOutsideClick: PropTypes.func,
  className: PropTypes.string,
  scroll: PropTypes.bool,
  enableCloseIfOutside: PropTypes.bool,
};

Modal.defaultProps = {
  scroll: true,
  enableCloseIfOutside: true,
};

export default Modal;
