import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import truncate from 'truncate';

import Select from 'components/shared/select';
import CollectedDataAPI from 'apis/collected-data-api';
import MultipleChoiceVisualizer from './data-visualizer/multiple-choice-visualizer';
import LocationVisualizer from './data-visualizer/location-visualizer';
import MoneyVisualizer from './data-visualizer/money-visualizer';
import NumberVisualizer from './data-visualizer/number-visualizer';

const TYPES_WITH_VISUALIZATION = ['address', 'multiple_choice', 'money', 'integer', 'float'];

const DataVisualizer = ({ visualizableFields, public: isPublic }) => {
  const actuallyVisualizableFields = visualizableFields.filter(
    (field) => TYPES_WITH_VISUALIZATION.includes(field.data_type),
  );
  const [selectedDatumId, setSelectedDatumId] = useState(actuallyVisualizableFields[0]?.id);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const activeField = visualizableFields.find(({ id }) => id === selectedDatumId);

  const selectedVisualizationIsSupported = (
    activeField && actuallyVisualizableFields.includes(activeField)
  );

  useEffect(() => {
    if (selectedVisualizationIsSupported) {
      setLoading(true);
      CollectedDataAPI.visualizationData(selectedDatumId)
        .done(setData)
        .always(() => setLoading(false));
    }
  }, [selectedDatumId, selectedVisualizationIsSupported]);

  const onSelectionChanged = ({ value }) => {
    setSelectedDatumId(value);
    setData(null);
  };

  // TODO: should this only show visualizable fields?
  const options = visualizableFields.map(({ id, section_name: sectionName, name }) => ({
    value: id,
    label: `(${truncate(sectionName, 10)}) ${name}`,
  }));

  const renderVisualization = () => {
    if (activeField) {
      if (loading) {
        // TODO render loading screen
        return;
      }

      if (!data) {
        return 'There is not currently data to visualize for this step.';
      }

      switch (activeField.data_type) {
      case 'address':
        return <LocationVisualizer markerData={data} public={isPublic} />;
      case 'multiple_choice':
        return <MultipleChoiceVisualizer data={data} />;
      case 'money':
        return <MoneyVisualizer data={data} />;
      case 'integer':
        return <NumberVisualizer data={data} />;
      case 'float':
        return <NumberVisualizer data={data} />;
      default:
        return 'Visualization is not supported for this field.';
      }
    }
    return 'This form does not have visualizable data';
  };

  return (
    <div className='datavisualizer goldenwidth'>
      <div className='cf margin-bottom'>
        <h2 className='uppercase inline-block margin-right'>Data Visualizer</h2>
        {(visualizableFields.length > 0) && (
          <Select
            options={options}
            onChange={onSelectionChanged}
            value={options.find(({ value }) => value === selectedDatumId)}
            placeholder='Select field to visualize...'
            // If messing with the z-index doesn't help, this might
            // work somehow in conjunction.
            // https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown
            // menuPortalTarget={document.body}
            styles={{
              container: (provided) => ({
                ...provided,
                display: 'inline-block',
                width: '100%',
                maxWidth: '420px',
              }),
              menu: (provided) => ({
                ...provided,
                // Without z-index here, the map controls
                // show up on top of the menu when the map
                // visualizer is rendered.
                // Yes, for whatever reason, leaflet decided
                // that 1000 was a reasonable z-index for those
                // controls.
                zIndex: 1001,
              }),
            }}
          />
        )}
      </div>

      <div>
        {renderVisualization()}
      </div>
    </div>
  );
};

DataVisualizer.propTypes = {
  visualizableFields: PropTypes.array,
  public: PropTypes.bool,
};

export default DataVisualizer;
