import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SearchPagesAPI from 'apis/search-pages-api';
import SearchBar from 'components/shared/search-bar';
import SearchResults from 'components/shared/search-results';
import { error as flashError } from 'components/shared/flash';
import KEYS from '../constants/keys';

const PublicSearch = ({ flowTemplateId, instructions }) => {
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('contains');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const searchTypeChanged = ({ value }) => {
    setSearchType(value);
  };

  const searchChanged = (e) => {
    setSearchTerm(e.target.value);
  };

  const search = (_, page) => {
    setResults(null);
    setSearching(true);
    setCurrentPage(1);
    setTotalPages(0);
    setRowsPerPage(0);
    setTotalResults(0);

    const params = {
      search_terms: searchTerm,
      template_id: flowTemplateId,
      search_type: searchType,
      page: page || 1,
    };

    SearchPagesAPI.publicSearch(params)
      .done((response) => {
        setResults(response.initiated_flows);
        setCurrentPage(response.pagination.current_page);
        setRowsPerPage(response.pagination.per_page);
        setTotalResults(response.pagination.total_count);
      })
      .fail(() => { flashError(); })
      .always(() => setSearching(false));
  };

  const onInputKeyUp = (e) => {
    if (e.keyCode === KEYS.ENTER_KEY) {
      e.preventDefault();
      search(e, 1);
    }
  };

  return (
    <div className='publicsearch'>
      <div className='margin-bottom-more'>
        {instructions}
      </div>
      <SearchBar
        searchTypeChanged={searchTypeChanged}
        searchType={searchType}
        search={search}
        searchButtonId='searchbutton'
      >
        <input type='text' onChange={searchChanged} onKeyUp={onInputKeyUp} value={searchTerm} placeholder='Search...' />
      </SearchBar>
      <div>
        <SearchResults
          results={results}
          searching={searching}
          type='public'
          paginated
          currentPage={currentPage}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          totalResults={totalResults}
          searchHandler={search}
        />
      </div>
    </div>
  );
};

PublicSearch.propTypes = {
  flowTemplateId: PropTypes.number,
  instructions: PropTypes.node,
};

export default PublicSearch;
