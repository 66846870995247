import PropTypes from 'prop-types';
import React, { useState, useRef, useContext } from 'react';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import CopyableLink from 'components/shared/copyable-link';
import Modal from 'components/shared/modal';
import Textarea from 'react-textarea-autosize';
import SurveyActions from 'actions/initiated_flow/survey-actions';
import FileInput from 'components/shared/file-input';
import { publicHost } from 'modules/url-generator';
import SurveyResponses from './surveys/survey-responses';
import SurveyInvitations from './surveys/survey-invitations';

const SurveyStep = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);
  const [inviting, setInviting] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState('');

  const emailsTextarea = useRef();

  function onInviteClick() {
    setInviting(true);
    setMessage(props.step.survey.default_invite_message || '');
  }

  function onSendClick() {
    const token = props.activeStep.survey_token;

    switch (inviteMethod()) {
    case 'list': {
      const emails = emailsTextarea.current.value;
      SurveyActions.inviteByList(dispatch, token, emails, message)
                     .done(resetState);
      break;
    }
    case 'file':
      SurveyActions.inviteByCSV(dispatch, token, selectedFile, message)
                   .done(resetState);
      break;
    default:
      throw `Unrecognized invite method ${inviteMethod()}`;
    }
  }

  function inviteMethod() {
    return selectedFile ? 'file' : 'list';
  }

  function resetState() {
    setInviting(false);
    setSelectedFile(null);
  }

  function onFileSelect(e) {
    setSelectedFile(e.currentTarget.files[0]);
  }

  function onMessageChange(e) {
    setMessage(e.currentTarget.value);
  }

  function renderSurveyLink() {
    if (!props.active) { return; }

    const { survey_token } = props.activeStep;
    const linkURL = `${publicHost()}/surveys/${survey_token}`;

    return (
      <section className='margin-bottom-more'>
        <h5 className='block'>Link to survey</h5>
        <div className='inline-block' style={{ width: '400px' }}>
          <CopyableLink linkURL={linkURL} />
        </div>
        <button onClick={onInviteClick} className=' margin-left btn-thirdary'>Invite...</button>
      </section>
    );
  }

  function renderSurvey() {
    const { survey_submissions, survey_invitations, id, initiated_flow_id } = props.activeStep;

    return (
      <div>
        {renderSurveyLink()}

        <SurveyResponses
          activeStepId={id}
          surveySubmissions={survey_submissions}
          initiatedFlowId={initiated_flow_id}
          taskView={props.taskView}
        />

        <SurveyInvitations
          activeStepId={id}
          surveyInvitations={survey_invitations}
        />
      </div>
    );
  }

  function renderNoInfoNeeded() {
    return (
      <p>Submission is in progress. No additional information is needed from you at this time.</p>
    );
  }

  function renderPublic() {
    if (props.step.external) {
      return renderSurvey();
    }

    return renderNoInfoNeeded();
  }

  function renderMessageBox() {
    return (
      <div className='padding-top'>
        <label>
          <span>Invite Message</span>
          <Textarea className='block fullwidth margin-bottom' value={message} placeholder='Message for survey invite email' onChange={onMessageChange} />
        </label>
      </div>
    );
  }

  function renderPasteBox() {
    if (!selectedFile) {
      return (
        <>
          <p>Paste a list of emails, one per line.</p>
          <Textarea className='block fullwidth margin-bottom' ref={emailsTextarea} placeholder={'email@example.com\nemail@example.com'} />
        </>
      );
    }
  }

  function renderFileUpload() {
    let content;

    if (selectedFile) {
      content = selectedFile.name;
    } else {
      content = (
        <>
          <span>Or </span>
          <FileInput
            onChange={onFileSelect}
            className='inline-block'
            label='upload a csv of emails'
          />
          <span className='margin-left-less color-text-medium'>(Ensure the file type is .csv and the emails are in the first column.)</span>
        </>
      );
    }

    return <div>{content}</div>;
  }

  function sendButtonText() {
    if (selectedFile) {
      return 'Upload and send invites';
    }

    return 'Send invites';
  }

  function renderInviteManager() {
    if (inviting) {
      return (
        <Modal onOutsideClick={resetState}>
          <h1>Send survey invites</h1>
          {renderPasteBox()}
          {renderFileUpload()}
          {renderMessageBox()}
          <button className='btn-primary margin-top' onClick={onSendClick}>{sendButtonText()}</button>
        </Modal>
      );
    }
  }

  return (
    <div className='currentstep-stepsection surveystep'>
      {props.admin ? renderSurvey() : renderPublic()}
      {renderInviteManager()}
    </div>
  );
};

SurveyStep.propTypes = {
  active: PropTypes.bool.isRequired,
  activeStep: PropTypes.shape({
    id: PropTypes.number.isRequired,
    survey_submissions: PropTypes.array.isRequired,
    survey_token: PropTypes.string.isRequired,
    survey_invitations: PropTypes.array.isRequired,
  }).isRequired,

  step: PropTypes.object.isRequired,
  admin: PropTypes.bool.isRequired,
};

export default SurveyStep;
