import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import dayjs from 'dayjs';
import { renewalOptIn, renewalOptOut } from 'actions/issued-output-actions';
import { confirm } from 'modules/alert-confirm';
import { isEmpty } from 'underscore';

const RenewalOptOutLink = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);

  const onRenewalOptIn = (e) => {
    e.preventDefault();
    const description = `This will re-enable notifications for <strong>${props.output.name}</strong>. ${props.admin ? 'The applicant' : 'You'} will be notified when renewal window is open.`;
    confirm('Are you sure?', description)
      .done(() => { renewalOptIn(dispatch, props.output.id); });
  };

  const onRenewalOptOut = (e) => {
    e.preventDefault();
    const description = `This will disable renewal notifications for <strong>${props.output.name}</strong>. ${props.admin ? 'The applicant' : 'You'} won't be notified when renewal window is open.`;
    confirm('Are you sure?', description)
      .done(() => { renewalOptOut(dispatch, props.output.id); });
  };

  const renderRenewalReminder = (reminder) => {
    let reminderDate = dayjs(props.output.expires_at);
    if (reminder.direction === 'before') {
      reminderDate = reminderDate.subtract(reminder.period_value, reminder.period_unit);
    } else {
      reminderDate = reminderDate.add(reminder.period_value, reminder.period_unit);
    }
    return (
      <div>
        <span>
          {reminderDate.format('LL')}
        </span>
      </div>
    );
  };

  const renderRenewalReminders = () => {
    if (isEmpty(props.output.simple_renewal_reminder_info)) {
      return (
        <td className='text-disabled-color'>
          None
        </td>
      );
    }
    return (
      <td className='bold'>
        {props.output.simple_renewal_reminder_info.map(renderRenewalReminder)}
      </td>
    );
  };

  if (!props.output.is_renewable) {
    return (
      <div className='output-renewaloptoutlink'>
        <table className='renewal-information'>
          <tbody>
            <tr className='titles'>
              <td>Renewals info</td>
            </tr>
            <tr className='values'>
              <td>Not renewable</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  if (props.output.renewal_opted_out) {
    const text = props.admin ? 'Applicant does not intend to renew.' : 'You do not intend to renew.';
    return (
      <div className='output-renewaloptoutlink'>
        <table className='renewal-information'>
          <tbody>
            <tr className='titles'>
              <td>Renewals info</td>
            </tr>
            <tr className='values'>
              <span className='text-disabled-color padding-right-less'>
                {text}
              </span>
              <button
                type='button'
                onClick={onRenewalOptIn}
              >
                <span>Undo</span>
              </button>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  const text = props.admin ? 'Click here if Applicant does not intend to renew' : 'I do not intend to renew';
  return (
    <div className='output-renewaloptoutlink'>
      <table className='renewal-information'>
        <tbody>
          <tr className='titles'>
            <td>Renewals open</td>
            <td>Renewals close</td>
            <td>Renewal reminders</td>
          </tr>
          <tr className='values'>
            <td className='bold'>{props.output.renewal_open_date ? dayjs(props.output.renewal_open_date).format('LL') : 'Currently open'}</td>
            <td className='bold'>{props.output.renewal_close_date && dayjs(props.output.renewal_close_date).format('LL')}</td>
            { renderRenewalReminders() }
          </tr>
        </tbody>
      </table>
      <button
        type='button'
        onClick={onRenewalOptOut}
      >
        <span>{text}</span>
      </button>
    </div>
  );
};

RenewalOptOutLink.propTypes = {
  output: PropTypes.shape({
    expires_at: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    is_renewable: PropTypes.bool.isRequired,
    renewal_open_date: PropTypes.string.isRequired,
    renewal_close_date: PropTypes.string.isRequired,
    renewal_opted_out: PropTypes.bool.isRequired,
    simple_renewal_reminder_info: PropTypes.arrayOf(
      PropTypes.shape({
        period_value: PropTypes.string.isRequired,
        period_unit: PropTypes.string.isRequired,
        direction: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  admin: PropTypes.bool.isRequired,
};

export default RenewalOptOutLink;
