import React, { useCallback } from 'react';

import Select from 'components/shared/select';
import { useAdvancedFilterContext } from 'components/shared/advanced-filter/context';

const AdvancedFilterSortBy = ({
  options,
  onSortBy,
}) => {
  const { currentFilter } = useAdvancedFilterContext();

  const onChange = useCallback(({ value }) => {
    onSortBy({ ...currentFilter, sort: value });
  }, [currentFilter]);

  return (
    <div className='dashboard-sort'>
      <label>
        Sort by
        <Select
          id='sort-select'
          options={options}
          defaultValue={options[0]}
          onChange={onChange}
          isSearchable={false}
          styles={{
            container: (provided) => ({
              ...provided,
              display: 'inline-block !important',
              fontSize: 'inherit !important',
              marginLeft: '8px',
              minWidth: '10.5rem',
              backgroundColor: '#FFF',
              borderRadius: '4px',
            }),
            menu: (provided) => ({
              ...provided,
              color: '#000',
            }),
          }}
        />
      </label>
    </div>
  );
};

export default AdvancedFilterSortBy;
