import React from 'react';
import XClose from 'components/shared/x-close'

const SlidingPanel = (props) => {
  return (
    <div ref={props.transitionRef} className='ifslidingpanel'>
      <div className='ifslidingpanel-header'>
        <h2>{props.title}</h2>
        <XClose id='closepanel' onClick={props.onCloseClick} label={`Close ${props.title}`} />
      </div>
      <div className='ifslidingpanel-contentwrap'>
        {props.children}
      </div>
    </div>
  )
}

export default SlidingPanel;