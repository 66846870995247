import React from 'react';
import SignaturePad from 'react-signature-pad';
import Flash from 'components/shared/flash';
import SignatureActions from 'actions/signature-actions';
import _ from 'underscore';
import NProgress from 'nprogress';

class SignaturePrompt extends React.Component {
  state = {
    submitting: false,
  };

  clearSignature = (e) => {
    e.preventDefault();
    this.refs.signaturepad.clear();
  };

  onSignClick = (e) => {
    e.preventDefault();

    const { signaturepad } = this.refs;

    if (signaturepad.isEmpty()) {
      Flash.error('A signature is required in order to continue');

      return;
    }

    this.setState({ submitting: true });
    NProgress.start();

    SignatureActions.sign(this.props.activeStepId, signaturepad.toDataURL())
      .done(this.onSuccess)
      .fail(this.onFail)
      .always(() => {
        this.setState({ submitting: false });
        NProgress.done();
      });
  };

  onCancelClick = (e) => {
    e.preventDefault();
    this.props.onCancel();
  };

  onSuccess = (res) => {
    if (_.isFunction(this.props.onSuccess)) {
      this.props.onSuccess(res);
    }
  };

  onFail = () => {
    Flash.render({
      type: 'error',
      message: 'Oops, that didn\'t work. If you continue to experience problems, please contact support.',
    });
  };

  render() {
    return (
      <div id='signaturerequester' className='popup'>
        <h1>Signature</h1>
        <label><span>Please sign using your mouse on the signature pad below. By signing, you certify that the information you're about to submit is true and correct to the best of your knowledge.</span></label>
        <SignaturePad
          ref='signaturepad'
          minWidth={2}
          maxWidth={5}
        />
        <div className='signaturerequester-footer cf'>
          <a onClick={this.clearSignature} href='#' className='btn-thirdary float-left'>Clear signature</a>
          <a onClick={this.onSignClick} href='#' className={`btn-primary float-right margin-left ${this.state.submitting ? 'disabled' : ''}`}>Sign</a>
          <a href='#' onClick={this.onCancelClick} className='signaturerequester-cancelbutton margin-right float-right'>Cancel</a>
        </div>
      </div>
    );
  }
}

export default SignaturePrompt;
