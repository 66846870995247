import SmartyStreetsSDK from 'smartystreets-javascript-sdk';

const SmartyStreetsCore = SmartyStreetsSDK.core;
const { Lookup } = SmartyStreetsSDK.usAutocomplete;

class SmartyStreetsClient {
  constructor() {
    if (!CityGrows.Server.websiteKey) {
      throw 'Smarty streets website key missing';
    }

    const credentials = new SmartyStreetsCore.SharedCredentials(CityGrows.Server.websiteKey);
    this.client = SmartyStreetsCore.buildClient.usAutocomplete(credentials);
  }

  getSuggestions(address) {
    const lookup = new Lookup(address);
    return this.client.send(lookup);
  }
}

export default SmartyStreetsClient;
