import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'underscore';

class FileInput extends React.Component {
  handleChange = (e) => {
    const returnValue = this.props.onChange(e);

    if (isFunction(returnValue)) {
      returnValue.done(this.resetFileInput);
    }
  };

  resetFileInput = () => {
    this.input.value = null;
  };

  triggerFileBrowse = (e) => {
    e.preventDefault();
    this.input.click();
  };

  render() {
    return (
      <div className={`fileinput ${this.props.className}`}>
        <input ref={(el) => this.input = el} type='file' accept={this.props.fileType} onChange={this.handleChange} disabled={this.props.disabled} />
        <a
          href='#'
          className={`secondary${this.props.disabled ? ' disabled' : ''}`}
          onClick={this.triggerFileBrowse.bind(this)}
        >
          {this.props.label}
        </a>
      </div>
    );
  }
}

FileInput.defaultProps = {
  fileType: '',
  disabled: false,
  label: 'Upload CSV',
};

FileInput.propTypes = {
  fileType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
};

export default FileInput;
