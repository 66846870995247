import { find } from 'underscore';
import AdminNotif from 'components/shared/admin-notif';
import Notification from 'components/shared/notification';
import PropTypes from 'prop-types';
import React from 'react';
import { HashRouter as Router, Route, Redirect } from 'react-router-dom';
import { Switch } from 'react-router';
import NotifBanner from 'components/shared/notif-banner';
import { adminHost } from 'modules/url-generator';
import SurveySubmission from './survey-submission-admin-view';
import Process from './process';
import PrimaryTabs from 'components/shared/primary-tabs';

const ActiveProcess = (props) => {
  // put this into the reducer
  function pastCurrentStep() {
    return props.initiatedFlowState.step.position > props.initiatedFlowState.initiatedFlow.current_step.position;
  }

  function renderAdminWarning() {
    const baseURL = adminHost();
    const processURL = `${baseURL}/initiated_flows/manager/${props.initiatedFlowState.initiatedFlow.id}`;
    const dashboardURL = `${baseURL}/`;

    return (
      <AdminNotif>
        <i className='icon-exclaim' />
        <span>
          You are viewing this submission as a constituent. You can also
          <a href={processURL}> view it as an admin </a>
          or
          <a href={dashboardURL}> return to your dashboard</a>
        </span>
      </AdminNotif>
    );
  }

  function renderNotifications() {
    const notifications = find(props.notifications, ((notif) => { return notif.type === 'custom_display'; }));
    if (notifications) {
      return notifications.message.messages.map((alert, index) => {
        return <Notification key={index} notificationType='semialert' extraClasses='process-page' notification={alert} />;
      });
    }
  }

  function renderPastCurrentStepWarning() {
    const currentStepURL = `/initiated_flows/manager/${props.initiatedFlowState.initiatedFlow.id}`;

    return (
      <NotifBanner style='notice'>
        <i className='icon-exclaim margin-right' />
        You have not reached this step yet. Click <a href={currentStepURL}>here</a> to go to the current step.
      </NotifBanner>
    );
  }

  function renderProcessView(routerProps) {
    return (
      <Process
        initiatedFlowState={props.initiatedFlowState}
        discussions={props.discussions}
        initiatedFlow={props.initiatedFlowState.initiatedFlow}
        currentUserId={props.currentUserId}
        admin={props.admin}
        flowTemplate={props.flowTemplate}
        masterTemplate={props.masterTemplate}
        {...routerProps}
      />
    );
  }

  function renderSurveySubmission(routerProps) {
    const submissions = props.initiatedFlowState.activeStep.survey_submissions;
    const submission = submissions.find((submission) => submission.id === Number(routerProps.match.params.id));

    return (
      <SurveySubmission
        survey={props.initiatedFlowState.step.survey}
        submission={submission}
        {...routerProps}
      />
    );
  }

  return (
    <Router>
      <div className='flexcolumn relative fullheight'>
        {props.showAdminWarning ? renderAdminWarning() : null}
        {pastCurrentStep() ? renderPastCurrentStepWarning() : null}
        {renderNotifications()}
        {props.admin && (
          <PrimaryTabs />
        )}
        <div id='tabheader-body'>
          <Switch>
            <Route exact path={['/', '/completed']} render={renderProcessView} />
            <Route exact path='/survey-response/:id' render={renderSurveySubmission} />

            {/* Catchall route */}

            <Route path='/'>
              <Redirect to='/' />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
};

ActiveProcess.propTypes = {
  showAdminWarning: PropTypes.bool.isRequired,
  currentUserId: PropTypes.number.isRequired,
  admin: PropTypes.bool.isRequired,
  flowTemplate: PropTypes.object.isRequired,
  masterTemplate: PropTypes.object.isRequired,
  notifications: PropTypes.array,
};

export default ActiveProcess;
