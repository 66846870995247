import React from 'react';
import PropTypes from 'prop-types';
import SignaturePad from 'react-signature-pad';
import Modal from 'components/shared/modal';
import ReviewActions from 'actions/review-actions';
import _ from 'underscore';
import Flash from 'components/shared/flash';

class SubmitReviewWidget extends React.Component {
  constructor(props) {
    super(props);

    this.signaturePad = React.createRef();

    this.state = {
      submitting: false,
    };
  }

  reviewText = () => {
    if (!!this.props.affidavit && this.props.affidavit.length > 0) {
      return this.props.affidavit;
    }
    return 'I certify that I have reviewed this submission';
  };

  clearSignature = (e) => {
    e.preventDefault();
    this.signaturePad.current.clear();
  };

  onCancelClick = (e) => {
    e.preventDefault();

    this.props.onClose();
  };

  onCloseClick = (e) => {
    e.preventDefault();
    this.onClose();
  };

  clearButton() {
    return (
      <div className='stepapprovales-clearsignature'>
        <a onClick={this.clearSignature} href='#' className='btn-thirdary'>Clear signature</a>
      </div>
    );
  }

  authorizeSubmit = () => {
    if (this.state.submitting) { return false; }

    if (this.signatureRequired() && this.signaturePad.current.isEmpty()) {
      Flash.error('A signature is required in order to continue');
      return false;
    }

    return true;
  };

  onSubmitReview = (e) => {
    e.preventDefault();

    if (!this.authorizeSubmit()) { return; }

    this.setState({
      submitting: true,
    });

    const signatureData = this.signatureRequired()
      ? this.signaturePad.current.toDataURL()
      : null;
    const { roleId } = this.props;
    ReviewActions.approve(this.props.reviewGroupId, this.props.activeStepId, this.props.approvalStatus, {
      comment: this.props.comment,
      signatureData,
      roleId,
    })
    .done(this.onReviewSubmitComplete)
    .fail(this.onClose);
  };

  onClose = () => {
    if (_.isFunction(this.props.onClose)) { this.props.onClose(); }
  };

  onReviewSubmitComplete = (res) => {
    this.setState({
      submitting: false,
    });

    this.onClose();
    if (_.isFunction(this.props.onReviewComplete)) { this.props.onReviewComplete(res); }
  };

  signatureRequired = () => {
    const signatureRequiredStatuses = [
      'approve',
      'reject',
    ];

    return this.props.requireSignature && _.contains(signatureRequiredStatuses, this.props.approvalStatus);
  };

  renderSignaturePad = () => {
    if (this.signatureRequired()) {
      return (
        <div className='margin-bottom fullwidth'>
          <SignaturePad ref={this.signaturePad} />
        </div>
      );
    }
  };

  reviewSubmitButtonText = () => {
    switch (this.props.approvalStatus) {
    case 'approve':
      return 'Approve';
    case 'reject':
      return 'Reject and halt';
    case 'feedback':
      return 'Request changes';
    default:
      throw `Unrecognized approval status ${this.props.approvalStatus}`;
    }
  };

  submitButtonClass = () => {
    const classes = ['btn-primary', 'float-right'];
    if (this.state.submitting) { classes.push('disabled'); }
    return classes.join(' ');
  };

  render() {
    return (
      <Modal>
        <div style={{ minWidth: '50vw' }}>
          <h1>Review</h1>
          <p dangerouslySetInnerHTML={{ __html: this.reviewText() }} />

          {this.renderSignaturePad()}
          <div className='linebreak' />

          {this.signatureRequired() && this.clearButton()}
          <a className={this.submitButtonClass()} onClick={this.onSubmitReview} href='#'>{this.reviewSubmitButtonText()}</a>
          <a href='#' style={{ lineHeight: '39px' }} onClick={this.onCancelClick} className='margin-right float-right'>Cancel</a>
        </div>
      </Modal>
    );
  }
}

SubmitReviewWidget.propTypes = {
  onReviewComplete: PropTypes.func,
  onClose: PropTypes.func,
  requireSignature: PropTypes.bool.isRequired,
  reviewGroupId: PropTypes.number.isRequired,
  activeStepId: PropTypes.number.isRequired,
  affidavit: PropTypes.string,
};

export default SubmitReviewWidget;
