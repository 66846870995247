import PropTypes from 'prop-types';
import React from 'react';
import PublicNavMenu from './public-nav-menu';

const OrgHeader = (props) => {
  function isLightColored() {
    if (!props.team.color) { return true; }

    return props.team.has_light_color;
  }

  const style = props.team.color ? { backgroundColor: props.team.color } : { backgroundColor: 'transparent' };
  const logoUrl = props.team.logo.thumb || props.team.logo;

  return (
    <div>
      <div className='public-react'>
        <div className={`orgheader cf${isLightColored() ? ' light' : ''}`} style={style}>
          <span className='relative'>
            <a href={`/${props.team.friendly_id}`}>
              <img src={logoUrl} alt='Logo' className='margin-right-less' />
            </a>

            <span className='orgheader-org'>
              <a href={`/${props.team.friendly_id}`}>{props.team.name}</a>
            </span>
          </span>
          <PublicNavMenu currentUser={props.currentUser} light={isLightColored()}/>
        </div>
      </div>
    </div>
  );
};

OrgHeader.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }),

  team: PropTypes.shape({
    friendly_id: PropTypes.string.isRequired,
    logo: PropTypes.string,
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    has_light_color: PropTypes.bool,
  }),
};

export default OrgHeader;
