import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'underscore';
import dayjs from 'dayjs';
import truncate from 'modules/truncate';

import SortingHeaderCell from '../sorting-header-cell';

const { Table } = require('fixed-data-table-2');
const { Column } = require('fixed-data-table-2');
const { Cell } = require('fixed-data-table-2');

const OutputsTable = (props) => {
  const wrapperElement = useRef();

  const [tableWidth, setTableWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);

  const outputs = props.outputs;

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  useEffect(() => {
    onResize();
  }, [onResize]);

  const onResize = debounce(() => {
    setTableHeight(contentHeight);
    setTableWidth(contentWidth);
  }, 100);

  const contentWidth = () => {
    return wrapperElement.current.offsetWidth;
  }

  const contentHeight = () => {
    return wrapperElement.current.offsetHeight;
  }

  const buildCell = (rowIndex, type) => {
    const output = outputs[rowIndex];

    if (!output) {
      props.loadMoreItems(rowIndex);
      return (
        <Cell key='dashboardloadingcell'>
          <div className='dashboard-loadingcell' />
        </Cell>
      );
    }

    const processHref = `/initiated_flows/${output.initiated_flow_id}`;

    return (
      <Cell>
        {buildCellContent(type, output)}
        <a className='spanlink' href={processHref} />
      </Cell>
    );
  }

  const buildCellContent = (type, output) => {
    switch (type) {
      case 'applicant':
        return (
          <div className='primaryApplicant'>
            <span>{truncate(output.primary_applicant.name, 30)}</span>
            <div className='color-text-medium text-smaller'>{truncate(output.primary_applicant.email, 30)}</div>
          </div>
        );
      case 'expire':
        const expire_value = output.expires_at ? dayjs(output.expires_at).format('l') : 'N/A';
        return (<span>{expire_value}</span>);
      case 'issued':
        const issued_value = output.issued_at ? dayjs(output.issued_at).format('l') : 'N/A';
        return (<span>{issued_value}</span>);
      case 'name':
        return (<span>{output.name}</span>);
      case 'pid':
        return (<span>{`#${output.initiated_flow_scoped_id}`}</span>);
      case 'status':
        return (<span>{output.status}</span>);
      case 'template_name':
        return (
          <span style={{ color: (`#${output.flow_template_color}`) }}>
            {output.flow_template_name}
          </span>
        );
      default:
        throw new Error(`Unhandled cell name ${type}`);
    };
  }

  return (
    <div ref={wrapperElement} className='dashboard-maincontent-rowwrap overflow-x-auto'>
      <Table
        rowsCount={props.totalCount}
        rowHeight={50}
        headerHeight={35}
        width={tableWidth}
        height={tableHeight}
      >
        <Column
          header={<Cell />}
          cell={(_) => null }
          width={65}
        />
        <Column
          header={<Cell>Document</Cell>}
          cell={(columnAttrs) => {
            return buildCell(columnAttrs.rowIndex, 'name');
          }}
          width={250}
        />
        <Column
          header={<Cell>Form</Cell>}
          cell={(columnAttrs) => {
            return buildCell(columnAttrs.rowIndex, 'template_name');
          }}
          width={250}
          cellClassName='flowtemplatename'
        />
        <Column
          header={<Cell>Submission ID</Cell>}
          cell={(columnAttrs) => {
            return buildCell(columnAttrs.rowIndex, 'pid');
          }}
          width={110}
          cellClassName='dashboard-processid'
        />
        <Column
          header={<SortingHeaderCell title='Issued' columnId='date_issued' activeOrder={props.activeOrder} onSorting={props.sortingFn} />}
          cell={(columnAttrs) => {
            return buildCell(columnAttrs.rowIndex, 'issued');
          }}
          width={120}
        />
        <Column
          header={<SortingHeaderCell title='Expiration date' columnId='date_expire' activeOrder={props.activeOrder} onSorting={props.sortingFn} />}
          cell={(columnAttrs) => {
            return buildCell(columnAttrs.rowIndex, 'expire');
          }}
          width={150}
        />
        <Column
          header={<Cell>Status</Cell>}
          cell={(columnAttrs) => {
            return buildCell(columnAttrs.rowIndex, 'status');
          }}
          width={100}
          cellClassName='capitalize'
        />
        <Column
          header={<Cell>Primary applicant</Cell>}
          cell={(columnAttrs) => {
            return buildCell(columnAttrs.rowIndex, 'applicant');
          }}
          width={250}
        />
      </Table>
    </div>
  );
};

export default OutputsTable;
