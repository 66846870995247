import React from 'react';
import PropTypes from 'prop-types';
import EntityField from './entity-field';
import RemoveEntityButton from './remove-entity-button'

const SelectedStandardEntity = ({
  entity,
  editing,
  entityName,
  setEntityName,
  customEntity,
  dispatch,
  removeEntity,
  cancelEdit,
  updateEntity,
  suggestEdits,
  edit,
  fieldState,
  disabled,
}) => {
  const anyEditable: boolean = entity && (entity.admin
    || entity.fields.some((field: { edit_suggestable: boolean }) => { return field.edit_suggestable; }));

  const renderEntityField = (field: object): EntityField => {
    return (
      <EntityField
        field={field}
        newEntity={false}
        editing={editing}
        entity={entity}
        fieldState={fieldState}
        dispatch={dispatch}
      />
    );
  };

  const renderEntityName = () => {
    if (entity.editable && editing) {
      return (
        <label> 
          {`${customEntity.name} Name`}
          <input
            type='text'
            value={entityName}
            onChange={(e) => setEntityName(e.target.value)}
          />
        </label>
      );
    }

    return (
      <span>{entity.name}</span>
    );
  };

  const renderEditButton = () => {
    if (anyEditable && editing) {
      return (
        <>
          <button
            type='button'
            className='btn-link secondary clickable margin-right-less'
            onClick={cancelEdit}
          >
            Cancel
          </button>

          {entity.editable
            ? (
              <button type='button' className='margin-left' onClick={updateEntity}>Update</button>
            )
            : (
              <button type='button' onClick={suggestEdits} className='margin-left'>Suggest edits</button>
            )}
        </>
      );
    }

    return (
      <button
        className='btn-link secondary clickable'
        type='button'
        onClick={edit}
      >
        Edit
      </button>
    );
  };

  return (
    <div className='entityinput raised'>
      <div className='entityinput-name'>
        {renderEntityName()}
        {!disabled && <RemoveEntityButton name={customEntity.name} removeEntity={removeEntity} />}
      </div>
      <ul>
        {entity.fields.map(renderEntityField)}
      </ul>
      <div className='align-right'>
        {renderEditButton()}
      </div>
    </div>
  );
};

SelectedStandardEntity.propTypes = {
  entity: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    admin: PropTypes.bool.isRequired,
    editable: PropTypes.bool.isRequired,
    name: PropTypes.string,
  }).isRequired,
  editing: PropTypes.bool.isRequired,
  entityName: PropTypes.string.isRequired,
  setEntityName: PropTypes.func.isRequired,
  customEntity: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  removeEntity: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  updateEntity: PropTypes.func.isRequired,
  suggestEdits: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  fieldState: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
};

export default SelectedStandardEntity;
