import React, {useRef, useEffect, useState} from 'react'; 
import { Table, Column, Cell } from 'fixed-data-table-2';
import LoadingSpinner from 'components/shared/loading-spinner';
import DashboardCell from 'components/dashboard/dashboard-cell';
import DashboardActions from 'actions/dashboard-actions';
import { debounce } from 'underscore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

const SubmissionsTable = ({initiatedFlows, totalCount, loading}) => {
  const dashboardElement = useRef();

  const [tableWidth, setTableWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  useEffect(() => {
    onResize();
  }, [totalCount, onResize]);

  const onResize = debounce(() => {
    setTableHeight(contentHeight);
    setTableWidth(contentWidth);
  }, 100);

  function dashboardHasContent() {
    return (initiatedFlows !== null
            && initiatedFlows.length > 0);
  }

  function isLoading() {
    return initiatedFlows === null;
  }

  function onFlowBoxClick() {
    return null
  }

  function buildCell(cellName, columnAttrs) {
    const initiatedFlow = initiatedFlows[columnAttrs.rowIndex];

    if (!initiatedFlow) {
      DashboardActions.nullCellRequested(columnAttrs.rowIndex);
      return (
        <Cell key='dashboardloadingcell'>
          <div className='dashboard-loadingcell' />
        </Cell>
      );
    }

    return (
      <DashboardCell
        item={initiatedFlow}
        cellName={cellName}
        onFlowBoxClick={onFlowBoxClick}
      />
    );
  }

  function contentWidth() {
    return dashboardElement.current.offsetWidth;
  }

  function contentHeight() {
    return dashboardElement.current.offsetHeight - 95;
  }

  function renderContent() {
    if (isLoading()) {
      return (
        <div className='dashboard-loading'>
          <LoadingSpinner size='large' />
        </div>
      );
    }

    return (
      <Table
        rowsCount={totalCount}
        rowHeight={50}
        headerHeight={35}
        width={tableWidth}
        height={535}
      >
        <Column
          header={<Cell>ID</Cell>}
          cell={(columnAttrs) => {
            return buildCell('id', columnAttrs);
          }}
          width={75}
          cellClassName='dashboard-processid'
        />

        <Column
          header={<Cell>Form Name</Cell>}
          cell={(columnAttrs) => {
            return buildCell('template_name', columnAttrs);
          }}
          width={225}
          flexGrow={1}
        />

        <Column
          header={<Cell>Assignees</Cell>}
          cell={(columnAttrs) => {
            return buildCell('responsible', columnAttrs);
          }}
          width={100}
          flexGrow={1}
        />

        <Column
          header={<Cell>Applicant</Cell>}
          cell={(columnAttrs) => {
            return buildCell('applicant', columnAttrs);
          }}
          width={200}
          flexGrow={1}
        />

        <Column
          header={<Cell>Action</Cell>}
          cell={(columnAttrs) => {
            return buildCell('action', columnAttrs);
          }}
          width={150}
          flexGrow={1}
        />

        <Column
          header={<Cell>Created</Cell>}
          cell={(columnAttrs) => {
            return buildCell('launched_at', columnAttrs);
          }}
          width={100}
          flexGrow={1}
        />

        <Column
          header={<Cell>Last Edited</Cell>}
          cell={(columnAttrs) => {
            return buildCell('last_edited', columnAttrs);
          }}
          width={100}
          flexGrow={1}
        />

      </Table>
    );
  }

  return (
    <div className='dashboard'>
      <div className='dashboard-maincontent'>
        <div className='dashboard-actionbar cf'>
          <div className='left'>
            <h1>Submissions Needing Attention</h1>
          </div>
          <div className='right'>
            <ul>
              <li>
                <a href='/dashboard' className='metricsdashboard-submissionslink'>
                  <FontAwesomeIcon icon={icon({ name: 'list' })} />
                  <span className='inline'>View all Submissions</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div ref={dashboardElement} className='dashboard-maincontent-rowwrap overflow-x-auto'>
          {renderContent()}
        </div>
      </div>
    </div>
  )
};

export default SubmissionsTable;