import React, { useCallback } from "react";
import { useDropzone } from 'react-dropzone';


const FileDropzone = ({
  onFileAccept,
  width,
  height,
  disabled,
  children,
}) => {
  const onDropCallback = useCallback((files) => {
    const file = files[0];
    if (file) { onFileAccept(file); }
  }, []);

  const {getRootProps, getInputProps} = useDropzone({
    onDrop: onDropCallback,
    multiple: false,
    disabled,
  });

  const rootProps = getRootProps({
    style: { width, height, marginBottom: '4px' },
    className: 'file-dropzone',
  });

  return (
    <div {...rootProps}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};


FileDropzone.defaultProps = {
  width: '250px',
  height: '140px',
};

export default FileDropzone;
