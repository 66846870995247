import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import TagManagerActions from 'actions/tag-manager-actions';
import { findWhere, find } from 'underscore';
import truncate from 'truncate';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirm } from 'modules/alert-confirm';
import pluralize from 'modules/pluralize';
import { error } from 'components/shared/flash';

const TagManager = (props) => {
  const reservedTagNames = ['late', 'renewal'];
  const createTagInput = useRef();
  const dispatch = useContext(InitiatedFlowDispatchContext);
  const [creatingTag, setCreatingTag] = useState(false);
  const [editingTagId, setEditingTagId] = useState(null);
  const editTagInput = useRef();

  useEffect(() => {
    if (creatingTag) {
      setEditingTagId(null);
      createTagInput.current.focus();
    }
  }, [creatingTag]);

  useEffect(() => {
    if (editingTagId) {
      setCreatingTag(false);
      editTagInput.current.focus();
    }
  }, [editingTagId]);

  const onEnterKeyPressed = (e) => {
    if (e.keyCode === 13) {
      if (creatingTag) {
        onSubmitNewTag();
      } else if (editingTagId) {
        onUpdateTagClick();
      }
    }
  };

  const isReservedTagName = (name) => {
    return reservedTagNames.includes(name);
  };

  function onSubmitNewTag() {
    if (!createTagInput.current) { return; }

    const tagName = createTagInput.current.value.toLowerCase().trim();
    const existingTag = findTag(tagName);
    if (existingTag) {
      if (!findWhere(props.taggings, { tag_id: existingTag.id })) {
        TagManagerActions.addTagging(dispatch, props.taggableType, props.taggableId, existingTag.id);
      }
    } else if (isReservedTagName(tagName)) {
      error('Name is reserved');
    } else {
      TagManagerActions
        .createTag(dispatch, props.taggableType, props.taggableId, tagName);
    }
    createTagInput.current.value = '';
    setCreatingTag(false);
  }

  function onTagClick(tagId) {
    if (tagIsSelected(tagId)) {
      const tagging = findTagging(tagId);
      TagManagerActions.removeTagging(dispatch, tagging.id);
    } else {
      TagManagerActions.addTagging(dispatch, props.taggableType, props.taggableId, tagId);
    }
  }

  function tagIsSelected(tagId) {
    return !!findTagging(tagId);
  }

  function findTag(tagName) {
    return find(props.suggestedTags, ((tag) => tag.name === tagName));
  }

  function findTagging(tagId) {
    return findWhere(props.taggings, { tag_id: tagId });
  }

  function onCreateTagClick(e) {
    e.preventDefault();

    setCreatingTag(true);
  }

  const onUpdateTagClick = () => {
    if (!editTagInput.current) { return; }
    const tag = findTag(editTagInput.current.value);
    console.log(editingTagId);
    if (tag) {
      if (tag.id === editingTagId) {
        setEditingTagId(null);
        return;
      }
      error('Name already taken');
    } else {
      console.log(editingTagId);
      TagManagerActions.updateTag(dispatch, editingTagId, editTagInput.current.value)
        .done(() => {
          setEditingTagId(null);
        });
    }
  };

  function onDeleteTagClick(tag) {
    const taggingCount = tagIsSelected(tag.id) ? tag.tagging_count - 1 : tag.tagging_count;
    if (taggingCount > 0) {
      confirm('Are you sure?', `This tag is in use on ${taggingCount} other ${pluralize('submission', taggingCount)}`, () => {
        TagManagerActions.destroyTag(dispatch, tag.id);
      });
    } else {
      TagManagerActions.destroyTag(dispatch, tag.id);
    }
  }

  function renderTagCreationSection() {
    let content;
    if (creatingTag) {
      content = <input ref={createTagInput} type='text' placeholder='New tag name' onKeyDown={onEnterKeyPressed} />;
    } else {
      content = <button onClick={onCreateTagClick} type='button' className='secondary btn-link'>Create new tag</button>;
    }

    return (
      <div className='tagmanager-new'>
        {content}
      </div>
    );
  }

  function renderSuggestedTags() {
    return props.suggestedTags.map(renderTag);
  }

  function renderActions(tag) {
    const canModifyTag = CityGrows.Server.canModifyTag && !isReservedTagName(tag.name);
    if (canModifyTag) {
      return (
        <div className='tagmanager-actions'>
          <i className='clickable icon-pencil-grey' onClick={() => setEditingTagId(tag.id)} role='button' title='Edit tag' />
          <i className='clickable icon-trash-grey' title='Delete tag' onClick={() => onDeleteTagClick(tag)} />
        </div>
      );
    }
  }

  function renderTag(tag) {
    const className = (tagIsSelected(tag.id) ? 'tagmanager-tagname active' : 'tagmanager-tagname');

    if (editingTagId === tag.id) {
      return (
        <li
          key={tag.id}
        >
          <div className={className}>
            <input type='text' className='tagmanager-editinput' defaultValue={tag.name} ref={editTagInput} onKeyDown={onEnterKeyPressed} />
          </div>
          <div className='tagmanager-actions'>
            <FontAwesomeIcon icon={icon({ name: 'check' })} size='lg' title='Save' onClick={() => onUpdateTagClick()} className='clickable' />
            <FontAwesomeIcon icon={icon({ name: 'xmark' })} size='lg' onClick={() => setEditingTagId(null)} title='Cancel' className='clickable' />
          </div>
        </li>
      );
    }
    return (
      <li
        key={tag.id}
      >
        <div className={className} onClick={() => { onTagClick(tag.id); }} title={tag.name}>
          {truncate(tag.name, 50)}
        </div>
        {renderActions(tag)}
      </li>
    );
  }

  return (
    <div className='tagmanager'>
      <div className='tagmanager-title'>Tags</div>
      <ul className='tagmanager-suggestions scroll'>
        {renderSuggestedTags()}
      </ul>

      {renderTagCreationSection()}
    </div>
  );
};

TagManager.propTypes = {
  taggings: PropTypes.arrayOf(PropTypes.shape({})),
  suggestedTags: PropTypes.arrayOf(PropTypes.shape({})),
  taggableType: PropTypes.string.isRequired,
  taggableId: PropTypes.number.isRequired,
};

export default TagManager;
