import FilterAPI from 'apis/filter-api';
import SearchAPI from 'apis/search-api';
import Flash from 'components/shared/flash';

const FilterActions = {
  save: (filter, dfault) => {
    return FilterAPI.save(filter, dfault);
  },

  update: (id, filter, dfault) => {
    return FilterAPI.update(id, filter, dfault);
  },

  remove: (id) => {
    return FilterAPI.remove(id);
  },

  toggleDefault: (id) => {
    return FilterAPI.toggleDefault(id);
  },

  searchApplicants(terms) {
    return SearchAPI.searchApplicants(terms);
  },

  loadTemplates: () => {
    return FilterAPI.templates()
                    .fail(() => {
                      Flash.error('There was a problem loading forms for filters.');
                    });
  },
};

export default FilterActions;
