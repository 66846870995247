import React, { useState, useContext, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import InitiatedFlowDispatchContext from 'contexts/initiated-flow-dispatch-context';
import DatePicker from 'react-datepicker';
import ExpandableContent from 'components/shared/expandable-content';
import RequestIsGovContext from 'contexts/request-is-gov-context';
import { update, revoke, unrevoke } from 'actions/issued-output-actions';
import truncate from 'truncate';
import pluralize from 'modules/pluralize';

import { confirm } from 'modules/alert-confirm';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { addYears } from 'react-datepicker/src/date_utils';
import OutputDownloadLink from './output-download-link';
import RenewalManager from './renewal-manager';
import RenewalOptOutLink from './renewal-opt-out-link';

dayjs.extend(localizedFormat);

const RevokeButton = ({ action, buttonText }) => {
  const [submitting, setSubmitting] = useState(false);
  const onRevokeClick = () => {
    setSubmitting(true);
    action()
      .always(() => setSubmitting(false));
  };

  return (
    <button type='button' name='revoke' onClick={onRevokeClick} disabled={submitting}>
      <span>
        {buttonText}
      </span>
    </button>
  );
};

RevokeButton.propTypes = {
  confirmationText: PropTypes.string,
  action: PropTypes.func,
  buttonText: PropTypes.node,
};

const Output = (props) => {
  const dispatch = useContext(InitiatedFlowDispatchContext);
  let isGov = useContext(RequestIsGovContext);
  const detailsNode = useRef(null);
  const expandIconNode = useRef(null);

  // TODO:
  // This is super hacky, should just be using a different context

  // override the Context if for a custom search page result

  if (props.isCustomResult) {
    isGov = false;
  }
  const [editingIssued, setEditingIssued] = useState(false);
  const [editingExpiration, setEditingExpiration] = useState(false);

  const { issued, revoked } = props.output;

  const renderIssueDetails = () => {
    const issueDate = new Date(props.output.issued_at);
    const currentDate = new Date();
    const issuedLabel = currentDate < issueDate ? 'To be issued' : 'Issued';

    return (
      <tr>
        <td>
          {issuedLabel}
        </td>
        <td className='output-issuedate padding-bottom-less'>
          {renderIssueDate()}
        </td>
      </tr>
    );
  };

  const renderExpirationDetails = () => {
    if (!props.output.expires || !props.output.issued) { return; }

    let label;
    if (props.output.expired) {
      label = <span className='color-alert'>Expired</span>;
    } else if (props.output.revoked) {
      label = <span className='color-alert'>Original expiration</span>;
    } else {
      label = <span className='color-success'>Valid through</span>;
    }

    return (
      <tr>
        <td>
          {label}
        </td>
        <td className='output-expiredate'>
          {renderExpirationDate()}
        </td>
      </tr>
    );
  };

  const renderExpirationDate = () => {
    if (editingExpiration) {
      return (
        <DatePicker
          selected={new Date(props.output.expires_at)}
          onChange={onExpirationDateUpdate}
          autoFocus
          onCalendarClose={() => { setEditingExpiration(false); }}
          maxDate={addYears(new Date(), 1000)}
        />
      );
    }

    const editButton = <button type='button' className='btn-link margin-left-less' onClick={onEditExpirationClick}>Edit</button>;

    return (
      <>
        {dayjs(props.output.expires_at).format('LL')}
        {shouldShowEditButtons() && editButton}
      </>
    );
  };

  const shouldShowEditButtons = () => {
    return isGov && props.style !== 'mini';
  };

  const renderStatusTag = () => {
    let tagLabel;

    const toggleCollapse = () => {
      detailsNode.current.classList.toggle('expand');
      expandIconNode.current.classList.toggle('open');
    };

    if (!props.output.issued) {
      tagLabel = 'Unissued';
    } else if (props.output.revoked) {
      tagLabel = 'Revoked';
    } else if (props.output.expired) {
      tagLabel = 'Expired';
    } else {
      tagLabel = 'Valid';
    }

    const openRevealArrowClass = props.collapse ? '' : 'open';

    return (
      <div onClick={toggleCollapse} className='output-status-wrapper'>
        <span className={`output-statustag static ${classNameForStatus()}`}>
          {tagLabel}
        </span>
        <i ref={expandIconNode} className={`icon-arrow-left bigger ${openRevealArrowClass}`}></i>
      </div>
    );
  };

  const renderHeaderDocumentLink = () => {
    const href = `/issued_outputs/${props.output.id}`;

    return <a href={href} target='_blank'>{truncate(props.output.name, 45)}</a>
  }

  const classNameForStatus = () => {
    if (props.output.revoked) {
      return 'revoked';
    } if (!props.output.issued) {
      return 'unissued';
    } if (props.output.expired) {
      return 'expired';
    }
    return 'valid';
  };

  const onEditIssuedClick = (e) => {
    e.preventDefault();

    setEditingIssued(true);
  };

  const onEditExpirationClick = (e) => {
    e.preventDefault();

    setEditingExpiration(true);
  };

  const onIssueDateUpdate = (date) => {
    update(dispatch, props.output.id, {
      issuedAt: date,
    });

    setEditingIssued(false);
  };

  const onExpirationDateUpdate = (date) => {
    update(dispatch, props.output.id, {
      expiresAt: date,
    });

    setEditingExpiration(false);
  };

  const renderIssueDate = () => {
    const issuedAt = props.output.issued ? dayjs(props.output.issued_at).format('LL')
      : 'Not yet issued';

    if (editingIssued) {
      return (
        <DatePicker
          selected={props.output.issued_at ? new Date(props.output.issued_at) : new Date()}
          onChange={onIssueDateUpdate}
          autoFocus
          onCalendarClose={() => { setEditingIssued(false); }}
          maxDate={addYears(new Date(), 1000)}
        />
      );
    }

    const editButton = <button type='button' className='btn-link margin-left-less' onClick={onEditIssuedClick}>Edit</button>;

    return (
      <>
        {issuedAt}
        {shouldShowEditButtons() && editButton}
      </>
    );
  };

  const classNameForStyle = () => {
    if (props.style === 'mini') {
      return 'mini';
    }

    return '';
  };

  const renderRenewals = () => {
    const renderRenewal = (r) => {
      return <li className='margin-bottom-less'><a href={`/initiated_flows/manager/${r.initiated_flow_id}`}>{r.flow_template_name} #{r.initiated_flow_scoped_id}</a></li>;
    };

    return props.output.simple_renewal_info.map(renderRenewal);
  };

  const renderRenewalInfo = () => {
    const html = [];

    if (props.output.renewal) {
      html.push(
        <dl className='margin-bottom'>
          <dt>Renewal</dt>
          <dd>Originally issued via <a href={`/initiated_flows/${props.output.original_output.initiated_flow_id}`} className='secondary'>{props.output.original_output.flow_template_name} #{props.output.original_output.initiated_flow_scoped_id}</a></dd>
        </dl>,
      );
    }

    if (props.output.renewed) {
      const renewalCount = props.output.simple_renewal_info.length;

      html.push(
        <ExpandableContent linkText={`Renewed ${renewalCount} ${pluralize('time', renewalCount)}`}>
          <ul>{renderRenewals()}</ul>
        </ExpandableContent>,
      );
    }

    return html;
  };

  const [renewing, setRenewing] = useState(false);

  const showRevoke = useMemo(() => {
    return issued && isGov;
  }, [issued, isGov]);

  const renderOutputActionContent = () => {
    if (renewing) {
      return (
        <RenewalManager
          output={props.output}
          onCancelClick={() => { setRenewing(false); }}
          variation={props.style}
        />
      );
    }

    return (
      <ul className='output-actions'>
        { props.output.is_currently_renewable
            && (
              <li>
                <button
                  type='button'
                  onClick={(e) => { e.preventDefault(); setRenewing(true); }}
                >
                  Renew
                </button>
              </li>
            )}
        <li>
          {renderDownloadLink()}
        </li>
        {showRevoke && (
          <li>
            {!revoked
              ? (
                <RevokeButton
                  buttonText='Revoke'
                  action={revokeAction}
                />
              )
              : (
                <RevokeButton
                  buttonText='Undo revoke'
                  action={unrevokeAction}
                />
              )}
          </li>
        )}
      </ul>
    );
  };

  const renderDownloadLink = () => {
    if (!props.output.revoked) {
      return (
        <OutputDownloadLink output={props.output} />
      );
    }
    return (
      <a
        href='#'
        aria-disabled='true'
        className='disabled output-downloadlink'
      >
        <span>Download</span>
      </a>
    );
  };

  const revokeAction = () => {
    // TODO: some other mechanism than confirm here.
    return confirm('Are you sure you want to revoke this output?', `This will make <strong>${props.output.name}</strong> unavailable for download. The applicant will be notified.`)
            .done(() => { revoke(dispatch, props.output.id); });
  };

  const unrevokeAction = () => {
    return confirm('Are you sure you want to re-issue this output?', `This will re-enable downloads for <strong>${props.output.name}</strong>`)
             .done(() => { unrevoke(dispatch, props.output.id); });
  };

  const expandDetailsClass = props.collapse ? '' : 'expand';

  return (
    <div className={`output ${classNameForStyle()} ${classNameForStatus()}`}>

      <div style={{ display: 'flex' }}>
        <i className='icon-doc' />
        <div className='doc-title'>
          {renderHeaderDocumentLink()}
        </div>

        {renderStatusTag()}
      </div>

      <div ref={detailsNode} className={`output-mainwrap collapse ${expandDetailsClass}`}>

        {renderRenewalInfo()}

        <table className='margin-top'>
          <tbody>
            {renderIssueDetails()}
            {renderExpirationDetails()}
          </tbody>
        </table>

        <RenewalOptOutLink
          output={props.output}
          admin={CityGrows.Server.admin}
        />
        {renderOutputActionContent()}
      </div>
    </div>
  );
};

Output.propTypes = {
  output: PropTypes.shape({
    id: PropTypes.isRequired,
    expired: PropTypes.bool.isRequired,
    expires: PropTypes.bool.isRequired,
    expires_at: PropTypes.string.isRequired,
    is_currently_renewable: PropTypes.bool.isRequired,
    issued: PropTypes.bool.isRequired,
    issued_at: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    renewal: PropTypes.bool.isRequired,
    revoked: PropTypes.bool.isRequired,
  }).isRequired,
  style: PropTypes.string,
  isCustomResult: PropTypes.bool,
  collapse: PropTypes.bool,
};

Output.defaultProps = {
  style: 'normal',
  collapse: false,
};

export default Output;
