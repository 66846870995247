/*
 * This component provides a wrapper around react-select
 * that provides props that allow for our styling of the
 * component to take precedence.
*/
import React from 'react';
import ReactSelect from 'react-select';

const noop = () => null;
const defaultComponents = { IndicatorSeparator: noop };
const Select = (props) => (
  <ReactSelect
    // You need both of these for things to work -
    // the className applies to the root, and the
    // prefix applies to all child stuff.
    classNamePrefix='react-select'
    className='react-select'
    components={ props.components || defaultComponents }
    ref={props.innerRef}
    styles={{
      ...props.styles,
      control: (baseStyles) => ({
        ...baseStyles,
        borderColor: '#CBCECF',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: '#0463B7',
      }),
    }}
    {...props}
    isDisabled={props.disabled}
  />
);

export default Select;
