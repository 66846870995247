import React from 'react';
import truncate from 'truncate';

const UserMenu = (props) => {
  return (
    <li className='nav-profilelink public'>
      <div className='nav-profilelink-email dashboard-link'>
        {truncate(props.currentUser.name, 25)}
      </div>
      <div className='popup nopadding'>
        <div className='popup-nub' />
        <ul className='popup-listmenu' role='navigation' aria-label='User'>
          <li className='popup-listmenu-addendum'>
            <div> Logged in as</div>
            <div title={props.currentUser.email}>{truncate(props.currentUser.email, 25)}</div>
          </li>
          <li><a href='/account'>My profile</a></li>
          <li>
            <a href='/users/sign_out' data-method='delete'>
              Sign out
            </a>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default UserMenu;
