import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import OverviewTabs from 'components/public/overview-tabs';
import DescriptionEditor from 'components/public/overview-description-editor';
import OrgHeader from 'components/shared/org-header';
import _ from 'underscore';

class PublicOverviewHeader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.header = createRef();
    this.bannerform = createRef();
    this.bannerfield = createRef();
    this.headerHeight = null;

    this.state = {
      showMiniNav: false,
    };
  }

  componentDidMount() {
    this.setScrollEvent();
    this.setBannerHeight();
  }

  componentWillUnmount() {
    this.unsetScrollEvent();
  }

  setBannerHeight = () => {
    this.headerHeight = $(this.header.current).height();
  };

  setScrollEvent = () => {
    $(window).bind('scroll', this.didScroll);
  };

  unsetScrollEvent = () => {
    $(window).unbind('scroll', this.didScroll);
  };

  didScroll = _.throttle(() => {
    const offset = $(window).scrollTop();

    if (offset > this.headerHeight + 10 && !this.state.showMiniNav) {
      this.setState({
        showMiniNav: true,
      });
    } else if (offset < this.headerHeight && this.state.showMiniNav) {
      this.setState({
        showMiniNav: false,
      });
    }
  });

  getScrollStyle = () => {
    if (this.state.showMiniNav) {
      return {
        marginTop: this.headerHeight,
      };
    }
  };

  bgImageStyle = () => {
    const url = this.props.flowTemplateData.master_template.banner_image_url;
    if (!url) { return; }

    return {
      backgroundImage: `url(${url})`,
      backgroundColor: '#4F6A78',
    };
  };

  renderStartButton = () => {
    const buttonText = (() => {
      if (this.templateIsClosed()) {
        return 'This submission is not currently active. You will not be able to start it.';
      } if (!this.publiclyLaunchable()) {
        return 'This submission is not available to be launched';
      }
      return 'Start this submission';
    })();

    return (
      <a
        href={`/initiated_flows/manager?flow_template_id=${this.props.flowTemplateData.id}`}
        className={`btn-primary ${this.canBeLaunched() ? '' : 'disabled'}`}
        tabIndex='0'
        title={buttonText}
        id='startbutton'
        data-method='post'
      >
        Start
      </a>
    );
  };

  canBeLaunched = () => {
    return !this.templateIsClosed() && (this.publiclyLaunchable() || this.canLaunchInternally());
  };

  publiclyLaunchable = () => {
    return this.props.flowTemplateData.master_template.publicly_launchable;
  };

  templateIsClosed = () => {
    return this.props.flowTemplateData.master_template.status === 'closed';
  };

  renderNotice = () => {
    if (this.templateIsClosed()) {
      return <div className='inline-block color-left alert inverted'><strong>Note:</strong> This submission is not currently active. You will not be able to start it.</div>;
    }
  };

  onBannerUploadClick = () => {
    $(this.bannerfield.current).click();
  };

  onImageSelect = () => {
    this.bannerform.current.submit();
  };

  userIsAdmin = () => {
    return this.props.flowTemplateData.master_template.managable_by_user;
  };

  canLaunchInternally = () => {
    return this.props.flowTemplateData.master_template.internally_launchable_by_user;
  };

  renderBannerForm = () => {
    if (!this.userIsAdmin()) { return; }

    const csrfToken = $('meta[name=csrf-token]').first();
    if (!csrfToken) { throw 'Missing CSRF token'; }

    return (
      <form id='publicoverview-bannerform' ref={this.bannerform} encType='multipart/form-data' action={`/master_templates/${this.props.flowTemplateData.master_template.friendly_id}`} acceptCharset='UTF-8' method='post'>
        <input type='hidden' name='_method' value='patch' />
        <input type='hidden' name='authenticity_token' value={csrfToken.attr('content')} />
        <input onChange={this.onImageSelect} ref={this.bannerfield} className='hidden' type='file' name='master_template[banner_image]' />
        <a onClick={this.onBannerUploadClick} href='#'>Change banner image</a>
      </form>
    );
  };

  render() {
    return (
      <>
        <div id='publicoverview-headerwrap' style={this.getScrollStyle()} className={this.state.showMiniNav ? 'mini' : ''}>
          <section id='publicoverview-header' ref={this.header}>

            {this.renderBannerForm()}

            <div id='publicoverview-header-bgimage' style={this.bgImageStyle()} />
            <div id='publicoverview-header-overlay' />
            <nav className='cf public relative overview startpage' id='topnav'>
              <OrgHeader
                currentUser={this.props.user}
                team={this.props.flowTemplateData.team}
              />
            </nav>

            <div id='publicoverview-processstart' className=' relative column narrow cf'>
              <h1>{this.props.flowTemplateData.name}</h1>
              <DescriptionEditor
                description={this.props.flowTemplateData.description}
                flowTemplateId={this.props.flowTemplateData.id}
                admin={this.userIsAdmin()}
                onChange={this.setBannerHeight}
              />
              {this.renderNotice()}

              <div className='linebreak' />

              {this.renderStartButton()}
            </div>
          </section>
        </div>

        <OverviewTabs
          teamSlug={this.props.flowTemplateData.team.friendly_id}
          templateSlug={this.props.flowTemplateData.master_template.friendly_id}
          canPublicRead={this.props.flowTemplateData.master_template.public_read}
          templatePrivate={this.props.flowTemplateData.master_template.private}
          myTeams={this.props.myTeams}
          flowTemplateId={this.props.flowTemplateData.id}
          forkAllowed={this.props.flowTemplateData.master_template.fork_allowed}
          searchEnabled={this.props.flowTemplateData.master_template.publicly_searchable}
        />
      </>
    );
  }
}

PublicOverviewHeader.propTypes = {
  flowTemplateData: PropTypes.object.isRequired,
  myTeams: PropTypes.array,
  user: PropTypes.object.isRequired,
};

export default PublicOverviewHeader;
